// @flow

import { connect } from "react-redux";
import React, { useEffect, useRef } from "react";
import * as R from "ramda";

import Visualization from "./Visualization";
import Icon from "src/icons";
import {
  MyConversationChart as StyledConversation,
  SubTitle,
  IconButton
} from "./styles";
import { getMyConversatoinChart } from "src/actions/homescreen";
import Loader from "src/components/Dashboard/Body/Loader";
import { exportChart } from "src/utils/charts";

import type { AppState, HomescreenTile } from "src/types";

type Props = {
  loading: boolean,
  chartData: Object,
  tile: HomescreenTile,
  _getMyConversatoinChart: Function
};

const MyConversationChart = ({
  loading,
  chartData,
  tile,
  _getMyConversatoinChart
}: Props) => {
  const chartRef = useRef(null);
  const filter = tile.settings.filters;

  useEffect(() => {
    _getMyConversatoinChart({ tileId: tile.id, filter });
  }, [tile, _getMyConversatoinChart, filter]);

  if (!tile.settings.displayEmpty && R.isEmpty(chartData) && !loading) {
    return <></>;
  }

  return (
    <StyledConversation color={tile.settings.tileColor}>
      {loading && R.isEmpty(chartData) ? (
        <>
          <h4>{tile.title}</h4>
          <SubTitle>{tile.subTitle}</SubTitle>
          <Loader />
        </>
      ) : (
        <>
          {R.isEmpty(chartData) ? (
            <>
              <h4>{tile.settings.emptyMessage?.title || ""}</h4>
              <SubTitle>{tile.settings.emptyMessage?.subTitle || ""}</SubTitle>
            </>
          ) : (
            <>
              <h4>{tile.title}</h4>
              <SubTitle>{tile.subTitle}</SubTitle>
              <IconButton onClick={() => exportChart(chartRef)}>
                <Icon type="export" />
              </IconButton>
              <Visualization
                chartRef={chartRef}
                type="scrollColumn2d"
                dataSource={chartData}
              />
            </>
          )}
        </>
      )}
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chartData: app.homescreen.myConversationChart[props.tile.id] || {},
  loading: app.homescreen.loadingConversationChart[props.tile.id]
});

export default connect(mapStateToProps, {
  _getMyConversatoinChart: getMyConversatoinChart
})(MyConversationChart);
