// @flow

import { useSelector } from "react-redux";
import React from "react";

import {
  getCurrentChecklistBuilderFields,
  getChecklistFieldSettings,
  getChecklistFieldType,
  getChecklistFieldLabel,
  getFormChecklistFields
} from "src/reducers";
import { convertToCamelCase, convertToSentence } from "src/utils";
import {
  conditionByField,
  supportedFields,
  fieldList,
  fields as fieldMap
} from "src/conditions";
import Select from "src/components/Select";
import ValueDropdown from "./ValueField";
import { MultiSelect } from "src/components/Unifize";
import Icon from "src/icons";

import type { FieldId, ConditionField } from "src/types";

import { inkLighter } from "src/styles/constants/colors";
import { Flex, FormLabel, Text, IconButton } from "@chakra-ui/react";
import { styles } from "./styles";

type Props = {
  fieldId: ?FieldId,
  updateCondition: Function,
  blockIndex: number,
  conditionIndex: number,
  type: ?string,
  value: any,
  field: string,
  removeCondition: Function,
  selectedFormId?: number
};

const Condition = ({
  fieldId,
  blockIndex,
  conditionIndex,
  updateCondition,
  type,
  value = [],
  field,
  removeCondition,
  selectedFormId
}: Props) => {
  const currentChecklistFields = useSelector(({ app }) =>
    getCurrentChecklistBuilderFields(app)
  );

  const checklistFieldType = useSelector(({ app }) =>
    getChecklistFieldType(app, fieldId)
  );
  const checklistFieldLabel = useSelector(({ app }) =>
    getChecklistFieldLabel(app, fieldId)
  );
  const checklistFieldSettings = useSelector(({ app }) =>
    getChecklistFieldSettings(app, fieldId)
  );
  const selectedFormFields = useSelector(({ app }) =>
    getFormChecklistFields(app, selectedFormId ? `${selectedFormId}` : "")
  );
  const selectedFormFieldsLabels = useSelector(({ app }) =>
    selectedFormFields.map(fieldId => getChecklistFieldLabel(app, fieldId))
  );

  if (!currentChecklistFields) {
    return null;
  }

  const handleConditionUpdate = (fieldInfo: FieldId | ConditionField) => {
    let condition = null;

    if (typeof fieldInfo === "number") {
      // If the field belongs to a form
      if (selectedFormFields && selectedFormFields.includes(fieldInfo)) {
        condition = {
          field: fieldMap.formField,
          checklistFieldId: fieldInfo,
          type: null,
          value: []
        };
      } else {
        condition = {
          field: fieldMap.checklistField,
          checklistFieldId: fieldInfo,
          type: null,
          value: []
        };
      }
    } else {
      condition = {
        field: fieldInfo,
        checklistFieldId: null,
        type: null,
        value: []
      };
    }
    updateCondition(condition, blockIndex, conditionIndex);
  };

  const selectedFieldType =
    field === fieldMap.checklistField || field === fieldMap.formField
      ? checklistFieldType
      : field;
  const fieldLabel =
    field === fieldMap.checklistField || field === fieldMap.formField
      ? checklistFieldLabel
      : field;

  return (
    <Flex mt={4}>
      <Flex {...styles.conditionSelectContainer}>
        <FormLabel {...styles.label}>Condition</FormLabel>
        <Select
          value={fieldLabel}
          placeholder="Field"
          onChange={handleConditionUpdate}
          itemPredicate={({ item, inputValue }) =>
            item.props.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        >
          {selectedFormFields && <Text variant="inkLightestSm">FORM DATA</Text>}
          {selectedFormFields &&
            selectedFormFields.map((fieldId, fieldIndex) => (
              <Text
                key={fieldId}
                variant="ink400"
                value={fieldId}
                label={selectedFormFieldsLabels[fieldIndex]}
              >
                {selectedFormFieldsLabels[fieldIndex]}
              </Text>
            ))}

          <Text variant="inkLightestSm">CHECKLIST DATA</Text>
          {currentChecklistFields
            .filter(field => supportedFields.includes(field.type))
            .map(field => (
              <Text
                key={field.id}
                variant="ink400"
                value={field.id}
                label={field.label}
              >
                {field.label || "<NO NAME>"}
              </Text>
            ))}
          <Text variant="inkLightestSm">CONVERSATION DATA</Text>
          {fieldList.map(field => (
            <Text key={field} variant="ink400" value={field} label={field}>
              {field}
            </Text>
          ))}
        </Select>
      </Flex>
      <Flex {...styles.qualifierContainer}>
        <FormLabel {...styles.label}>Qualifier</FormLabel>
        <Select
          value={convertToSentence(type || "") || "Value"}
          placeholder="Value"
          onChange={type =>
            updateCondition(
              { type: convertToCamelCase(type) },
              blockIndex,
              conditionIndex,
              false
            )
          }
        >
          {/* // $FlowFixMe */}
          {conditionByField[selectedFieldType]?.map(type => (
            <Text key={type} variant="ink400" value={type}>
              {type}
            </Text>
          ))}
        </Select>
      </Flex>
      <ValueDropdown
        type={type}
        checklistFieldType={checklistFieldType}
        selectedFieldType={selectedFieldType}
        updateCondition={updateCondition}
        blockIndex={blockIndex}
        conditionIndex={conditionIndex}
        value={value}
        fieldSettings={JSON.parse(checklistFieldSettings)}
      />
      {conditionIndex !== 0 && (
        <IconButton
          {...styles.removeConditionButton}
          icon={<Icon type="close" color={inkLighter} />}
          onClick={() => removeCondition(blockIndex, conditionIndex)}
        />
      )}
    </Flex>
  );
};

export default Condition;
