// @flow

import React from "react";

import {
  SelectedContent,
  SelectedItems,
  SelectedItem,
  RemoveButton,
  InputContainer,
  SelectedLabel,
  ClearAllWrapper,
  ToggleButton,
  Input
} from "./styles";
import { Avatar, Checkbox } from "src/components/Unifize";

type Props = {
  showSelectAll?: boolean,
  selected: Array<any>,
  data: Array<Object>,
  keys: Array<string>,
  showIcon: boolean,
  placeholder: string,
  isOpen: boolean,
  selectedRef: any,
  openMenu: Function,
  handleDeselectAll: Function,
  getSelectedItemProps: Function,
  removeSelectedItem: Function,
  getInputProps: Function,
  getDropdownProps: Function,
  getComboboxProps: Function
};

const Selection = ({
  showSelectAll,
  selected,
  data = [],
  keys,
  showIcon,
  placeholder,
  isOpen,
  selectedRef,
  openMenu,
  handleDeselectAll,
  getSelectedItemProps,
  removeSelectedItem,
  getInputProps,
  getDropdownProps,
  getComboboxProps
}: Props) => {
  const identifier: string = keys[0];
  const display: string = keys[1];
  const url: string = keys[3] || "";

  return (
    <SelectedContent {...getComboboxProps({ ref: selectedRef })}>
      <SelectedItems showSelectAll={showSelectAll}>
        {selected.map((id, index) => {
          const item = data.find(item => item[identifier] === id);
          if (!item) {
            return null;
          }
          return (
            <SelectedItem
              key={`selected-item-${id}`}
              {...getSelectedItemProps({
                id,
                index
              })}
              onClick={e => {
                e.stopPropagation();
                removeSelectedItem(id);
              }}
            >
              {showIcon && (
                <Avatar
                  size="sm"
                  src={item[url] || ""}
                  alt={item[display] || ""}
                />
              )}
              <SelectedLabel title={`${item[display]}`}>
                {" "}
                {item[display]}
              </SelectedLabel>
              <RemoveButton>&#10005;</RemoveButton>
            </SelectedItem>
          );
        })}
        <InputContainer>
          <Input
            placeholder={placeholder}
            {...getInputProps(
              getDropdownProps({
                onClick: e => {
                  e.stopPropagation();
                  !isOpen && openMenu();
                }
              })
            )}
          />
        </InputContainer>
      </SelectedItems>
      {showSelectAll && selected.length > 0 && (
        <ClearAllWrapper>
          <ToggleButton onClick={handleDeselectAll}>&#10005;</ToggleButton>
        </ClearAllWrapper>
      )}
    </SelectedContent>
  );
};

export default Selection;
