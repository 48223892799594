// @flow

import React, { useRef, useEffect } from "react";
import { Container, Checkbox as StyledCheckbox, Label } from "./styles";
import Icons from "src/icons";

type Props = {
  checked: boolean,
  onChange: Function,
  label?: string,
  isDisabled?: boolean,
  height?: string,
  indeterminate?: boolean
};

const getIconString = (checked: boolean, indeterminate: boolean) => {
  if (indeterminate) {
    return "indeterminateCheckbox";
  } else if (checked) {
    return "activeCheckbox";
  }
  return "defaultCheckbox";
};

const Checkbox = ({
  checked,
  label,
  onChange,
  isDisabled = false,
  height = "20px",
  indeterminate = false
}: Props) => {
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate ?? false;
    }
  }, [indeterminate]);

  const handleChange = () => {
    if (isDisabled) return;
    onChange(!checked);
  };

  return (
    <Container onClick={handleChange} disabled={isDisabled} height={height}>
      <StyledCheckbox checked={checked} onChange={() => {}} ref={checkboxRef} />
      <Icons type={getIconString(checked, indeterminate)} />
      {label && <Label>{label}</Label>}
    </Container>
  );
};

export default Checkbox;
