// @flow

import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { getAllGroups } from "src/reducers";
import { MultiSelect } from "src/components/Unifize";

import type { Group as UnifizeGroup, GroupId } from "src/types";

type Props = {
  onChange: Function,
  value: Array<number>
};

const Group = ({ onChange, value }: Props) => {
  const groups: Array<UnifizeGroup> = useSelector(({ app }) =>
    getAllGroups(app)
  );

  // Convert group id to String, because conditions is
  // expecting group id to be a String instead of Number.
  const formattedGroups = groups.map(group => ({
    ...group,
    id: String(group.id)
  }));

  const handleSelection = (selected: Array<GroupId>) => {
    const selectedGroups = R.uniq(R.map(String, selected));
    onChange(selectedGroups);
  };

  return (
    <MultiSelect
      label="Value"
      labelWeight="bold"
      placeholder="Search"
      data={formattedGroups}
      selected={value}
      showIcon
      keys={["id", "name", "photoUrl"]}
      onChange={handleSelection}
      showSelectAll
    />
  );
};

export default Group;
