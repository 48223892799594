// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";

import { AddSubSection } from "src/components/OrgSettings/HomeScreen/styles";

export const NewConditionButton = styled(AddSubSection)`
  width: 60%;
  margin: 0;
`;

export const styles = {
  conditionContainer: {
    direction: "column",
    backgroundColor: colors.purpleLighter,
    padding: 4,
    width: "100%",
    marginBottom: 6
  },
  conditionSelectContainer: {
    direction: "column",
    width: "50%"
  },
  valueSelectContainer: {
    direction: "column",
    width: "100%"
  },
  qualifierContainer: {
    direction: "column",
    width: "50%",
    marginLeft: 4,
    marginRight: 4
  },
  addButton: {
    size: "sm",
    colorScheme: "blue",
    bg: "transparent",
    mt: 4,
    alignSelf: "flex-start"
  },
  label: {
    color: colors.primary,
    fontWeight: 700
  },
  behaviorContainer: {
    marginTop: 8,
    display: "flex",
    alignItems: "flex-start",
    gap: "1rem",
    justifyContent: "space-between"
  },
  behaviorDropdownContainer: {
    direction: "column",
    width: "40%"
  },
  behaviorLabelContainer: {
    alignItems: "center"
  },
  additionalBehaviorSettings: {
    direction: "column",
    width: "40%",
    paddingLeft: 8
  },
  iconContainer: {
    cursor: "pointer"
  },
  userItemContainer: {
    alignItems: "center"
  },
  removeConditionButton: {
    alignSelf: "center",
    size: "xs",
    mt: 8,
    ml: 4
  }
};
