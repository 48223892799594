// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getBuilderWorkflowId, getWorkflowBuilderStatuses } from "src/reducers";
import { MultiSelect } from "src/components/Unifize";

type Props = {
  onChange: Function,
  value: Array<number>
};

const Status = ({ onChange, value }: Props) => {
  const workflowId = useSelector(({ app }) => getBuilderWorkflowId(app));
  const statuses = useSelector(({ app }) => getWorkflowBuilderStatuses(app));

  return (
    <MultiSelect
      label="Value"
      labelWeight="bold"
      placeholder="Search"
      data={statuses}
      selected={value}
      keys={["id", "text"]}
      onChange={onChange}
      showSelectAll
    />
  );
};

export default Status;
