// @flow

import React, { useMemo } from "react";
import { List } from "react-virtualized";
import { Avatar, Checkbox } from "src/components/Unifize";
import { DropdownItem, DropdownText, ItemTitle, ItemSubTitle } from "./styles";

type Props = {
  items: Array<Object>,
  keys: Array<string>,
  highlightedIndex: number,
  getItemProps: Function,
  selected: Array<any>,
  showIcon: boolean,
  showCheckBox: boolean,
  rowHeight?: number
};

const VirtualDropdown = ({
  items,
  keys,
  highlightedIndex,
  getItemProps,
  showIcon,
  selected,
  showCheckBox,
  rowHeight = 38
}: Props) => {
  const renderRow = ({ key, index, style }) => {
    const item = items[index];
    if (!item) return;

    const id: string = keys[0];
    const display: string = keys[1];
    const description: string = keys[2] || "";
    const url: string = keys[3] || "";
    return (
      <DropdownItem
        key={key}
        style={style}
        className={highlightedIndex === index && "highlighted"}
        {...getItemProps({ item, index })}
      >
        {showIcon && <Avatar size="lg" src={item[url]} alt={item[display]} />}
        {showCheckBox && (
          <Checkbox
            checked={selected.includes(item[id])}
            onChange={() => null}
          />
        )}
        <DropdownText>
          <ItemTitle>{item[display]}</ItemTitle>
          {description && <ItemSubTitle>{item[description]}</ItemSubTitle>}
        </DropdownText>
      </DropdownItem>
    );
  };

  const listHeight = useMemo(
    () => Math.min(items.length * rowHeight, 190),
    [items.length, rowHeight]
  );

  return (
    <List
      width={1}
      height={listHeight}
      rowCount={items.length}
      rowHeight={rowHeight}
      rowRenderer={renderRow}
      scrollToIndex={highlightedIndex}
      style={{ width: "100%" }}
      containerStyle={{
        width: "100%",
        maxWidth: "100%"
      }}
    />
  );
};

export default VirtualDropdown;
