// @flow

import Link from "redux-first-router-link";
import * as R from "ramda";
import React, { useCallback, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Flex, IconButton, Text } from "@chakra-ui/react";

import RefreshButton from "src/components/chart/RefreshButton";
import ChartBody from "./ChartBody";
import * as atypes from "src/constants/actionTypes";
import Icon from "src/icons";
import {
  setNewChartDialogAttributes,
  showEditChartModal,
  copyChartUrl,
  editChart,
  fetchChart,
  deleteChart
} from "src/actions/chart";
import * as colors from "src/styles/constants/colors";
import { getInstanceReportId } from "src/reducers";
import {
  getInstanceFilter,
  getAdditionalFiltersChartId,
  getChart,
  getInstanceFilterId
} from "src/reducers";
import { setReports } from "src/actions/reports";
import * as styles from "./styles";
import { comparisonCharts } from "src/constants/charts.js";
import DeleteModal from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/Series/DeleteModal";
import { exportChart } from "src/utils/charts";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  reportId: string,
  chartId: ?string,
  filter: WorkflowInstanceFilter,
  _showEditChartModal: Function,
  _copyChartUrl: Function
};

const Expand = ({
  reportId,
  chartId,
  filter,
  _showEditChartModal,
  _copyChartUrl
}: Props) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const comparisonChartList = R.values(comparisonCharts);

  const chartDetails = useSelector(
    ({ app }) => chartId && getChart(app, chartId)
  );
  const workflowId = useSelector(({ app }) => getInstanceFilterId(app));

  const preventNavigation = useCallback(() => {
    _copyChartUrl();
    return false;
  }, [_copyChartUrl]);

  const handleEdit = (
    event: SyntheticMouseEvent<HTMLDivElement>,
    chartId: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const { type } = chartDetails;
    const isComparisonChart = comparisonChartList.includes(type);
    if (isComparisonChart) {
      dispatch(
        editChart({
          id: chartId,
          type,
          reportId,
          workflowId
        })
      );
      dispatch(fetchChart(chartId));
    } else {
      _showEditChartModal(chartId);
    }
  };

  const handleDelete = () => {
    dispatch(deleteChart(Number(chartId)));
    dispatch(setReports(reportId));
  };

  if (chartId) {
    return (
      <Flex sx={{ flexDirection: "column", gap: "1.5rem" }}>
        <Flex sx={styles.ChartHeader}>
          <Flex sx={styles.ChartActionIconsWrapper}>
            <RefreshButton chartId={chartId} />
            <Link
              id="chartShareURL"
              onClick={preventNavigation}
              to={{
                type: atypes.SET_REPORTS_REQUEST,
                payload: {
                  id: reportId
                },
                meta: {
                  query: {
                    ...filter,
                    chartId
                  }
                }
              }}
            >
              <IconButton
                sx={styles.IconButton}
                icon={<Icon type="shareLink" color={colors.inkLightest} />}
              />
            </Link>

            <IconButton
              sx={{
                ...styles.IconButton,
                w: "auto"
              }}
              icon={<Icon type="export" size={20} color={colors.inkLightest} />}
              onClick={() => exportChart(chartRef)}
            />

            <IconButton
              sx={styles.IconButton}
              onClick={e => handleEdit(e, Number(chartId))}
            >
              <Icon
                type="edit"
                color={colors.inkLightest}
                height={16}
                width={16}
              />
            </IconButton>

            <DeleteModal
              title={chartDetails.title}
              handleDelete={handleDelete}
            />
          </Flex>
        </Flex>

        {chartDetails.description && (
          <Text sx={styles.ChartDiscription}>{chartDetails.description}</Text>
        )}

        <ChartBody
          chartId={chartId}
          title={chartDetails.title}
          chartRef={chartRef}
        />
      </Flex>
    );
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  chartId: getAdditionalFiltersChartId(app),
  filter: getInstanceFilter(app),
  reportId: getInstanceReportId(app)
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _showEditChartModal: showEditChartModal,
  _copyChartUrl: copyChartUrl
})(Expand);
