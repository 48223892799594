// @flow

import React from "react";
import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string
};

const Export = ({ size = 20, color = colors.inkLighter }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.7917 10.1458V12.4583H3.54167V10.1458H2V12.4583C2 13.3062 2.69375 14 3.54167 14H12.7917C13.6396 14 14.3333 13.3062 14.3333 12.4583V10.1458H12.7917ZM4.3125 5.52083L5.39937 6.60771L7.39583 4.61896V10.9167H8.9375V4.61896L10.934 6.60771L12.0208 5.52083L8.16667 1.66667L4.3125 5.52083Z"
      fill={color}
    />
  </svg>
);

export default Export;
