// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getCurrentChecklistBuilderFields } from "src/reducers";
import Accordion from "src/components/Accordion.v2";
import AccordionContent from "../AccordionContent";
import { MultiSelect } from "src/components/Unifize";
import Radio from "src/components/Radio";
import { RadioLabel } from "../styles";
import type { ApprovalFieldSettingsV2 } from "src/types";

type Props = {
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function
};

const LockFields = ({ settings, saveSettings }: Props) => {
  const checklistFields = useSelector(({ app }) =>
    getCurrentChecklistBuilderFields(app)
  );

  const handleOnChange = selected =>
    saveSettings(["lockFields", "fields"], selected);

  return (
    <Accordion title="Lock Fields">
      <AccordionContent>
        <Radio
          id="inherit"
          value="inherit"
          isDisabled={settings?.contingentApprovals.length === 0}
          checked={settings?.lockFields.mode === "inherit"}
          handleChange={() => saveSettings(["lockFields", "mode"], "inherit")}
        >
          <RadioLabel>
            Continue to lock fields under previous approval
          </RadioLabel>
        </Radio>
        <Radio
          id="new"
          value="new"
          isDisabled={false}
          checked={settings?.lockFields.mode === "new"}
          handleChange={() => saveSettings(["lockFields", "mode"], "new")}
        >
          <RadioLabel>
            Lock checklist fields during and post approval/rejection:
          </RadioLabel>
        </Radio>

        <MultiSelect
          showSelectAll
          placeholder="Select checklist fields to lock"
          data={checklistFields}
          selected={settings?.lockFields.fields}
          keys={["id", "label"]}
          onChange={handleOnChange}
        />
      </AccordionContent>
    </Accordion>
  );
};

export default LockFields;
