// @flow

import { useSelector } from "react-redux";
import React from "react";
import * as R from "ramda";

import { behaviorByField, behaviorToSettings } from "src/conditions";
import { convertToSentence } from "src/utils";
import Select from "src/components/Select";
import ConversationDropdown from "./ValueField/Conversation";
import { getChecklistFieldSettings } from "src/reducers";
import Name from "src/components/Form/Name";

import { Flex, FormLabel, Text, Checkbox, Input } from "@chakra-ui/react";
import { styles } from "./styles";

type Props = {
  fieldId: ?number,
  behavior: ?string,
  updateBehavior: Function,
  blockIndex: number,
  mandatory: ?boolean,
  currentFieldType: string,
  options: Array<string>,
  settings: Object,
  tooltip?: string,
  isFormField: boolean
};

const Behavior = ({
  fieldId,
  behavior,
  updateBehavior,
  blockIndex,
  mandatory,
  currentFieldType,
  options = [],
  isFormField,
  settings,
  tooltip
}: Props) => {
  const currentFieldSettings = useSelector(({ app }) =>
    JSON.parse(getChecklistFieldSettings(app, fieldId) || "{}")
  );

  const handleUpdateBehavior = behavior => {
    if (currentFieldType === "status") {
      updateBehavior(blockIndex, {
        current: behaviorToSettings[behavior],
        tooltip
      });
    } else {
      updateBehavior(blockIndex, {
        current: behaviorToSettings[behavior],
        mandatory,
        options
      });
    }
  };

  const onChange = <T>(option: T) =>
    updateBehavior(blockIndex, {
      mandatory,
      current: behavior,
      options: R.includes(option, options)
        ? R.reject(R.equals(option), options)
        : R.uniq([...options, option])
    });

  const handleSelect = (value: Array<string>) =>
    updateBehavior(blockIndex, {
      mandatory,
      current: behavior,
      options: value
    });

  const fieldSettings = isFormField ? currentFieldSettings : settings;

  return (
    <Flex {...styles.behaviorContainer}>
      <Flex {...styles.behaviorDropdownContainer}>
        <Flex {...styles.behaviorLabelContainer}>
          <FormLabel {...styles.label}>Behavior</FormLabel>
          {currentFieldType !== "status" && (
            <Checkbox
              isChecked={mandatory}
              onChange={e =>
                updateBehavior(blockIndex, {
                  ...(behavior ? { current: behavior } : {}),
                  mandatory: e.target.checked
                })
              }
              mb={1}
            >
              Mandatory if not hidden
            </Checkbox>
          )}
        </Flex>
        <Select
          value={convertToSentence(behavior || "")}
          placeholder="Behavior"
          onChange={handleUpdateBehavior}
        >
          {/* // $FlowFixMe */}
          {behaviorByField[currentFieldType]?.map(behaviorItem => (
            <Text key={behaviorItem} value={behaviorItem} variant="ink400">
              {behaviorItem}
            </Text>
          ))}
        </Select>
      </Flex>

      {currentFieldType === "status" && behavior === "locked" && (
        <Input
          placeholder="Optional tooltip text"
          value={tooltip || ""}
          onChange={e =>
            updateBehavior(blockIndex, {
              current: behavior,
              tooltip: e.target.value
            })
          }
          size="sm"
          sx={{
            alignSelf: "end",
            ml: 8,
            width: "40%"
          }}
        />
      )}

      {(behavior || "").match(
        /dependent(LinkedField|Conversation)(Include|Exclude)/
      ) ? (
        <ConversationDropdown
          type={fieldSettings.type}
          workflow={fieldSettings.workflow}
          onChange={handleSelect}
          value={options}
          selectedItemsPerRow={4}
        />
      ) : null}
      {behavior === "dependentPicklistInclude" ||
      behavior === "dependentPicklistExclude" ? (
        <Flex {...styles.additionalBehaviorSettings}>
          <FormLabel {...styles.label}>Value</FormLabel>
          <Select
            value={options}
            placeholder="Value"
            onChange={onChange}
            multiple
          >
            {/* // $FlowFixMe */}
            {fieldSettings?.options?.map(option => (
              <Text key={option} value={option} variant="ink400">
                {option}
              </Text>
            ))}
          </Select>
        </Flex>
      ) : null}
      {behavior === "dependentFormInclude" ||
      behavior === "dependentFormExclude" ? (
        <Flex {...styles.additionalBehaviorSettings}>
          <FormLabel {...styles.label}>Value</FormLabel>
          <Select
            value={options}
            placeholder="Value"
            onChange={onChange}
            multiple
          >
            {/* // $FlowFixMe */}
            {fieldSettings?.selectedForms?.map(form => (
              <Text key={form} value={form} variant="ink400">
                <Name id={form} />
              </Text>
            ))}
          </Select>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Behavior;
