// @flow

import * as R from "ramda";

import type { Action, UnifizeUser } from "../types";
import * as atypes from "../constants/actionTypes";

const initialState = {
  authenticated: false,
  displayName: "",
  email: "",
  emailVerified: false,
  uid: "",
  photoURL: "",
  photoUrl: "",
  uploadingPhoto: false,
  updatingName: false,
  phoneNumber: null,
  department: null
};

/**
 * This deals with currentUser part of the store
 * @param {UnifizeUser} state
 * @param {Action} param1
 */
const currentUser = (
  state: UnifizeUser = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        email: payload.email
      };
    case atypes.SET_CURRENT_USER:
      if (R.not(R.isEmpty(payload))) {
        const { name, email, uid } = payload;
        return {
          ...state,
          displayName: name,
          email,
          uid: R.has("uid", payload) && payload.uid ? payload.uid : state.uid // Overwrite the UID only if "uid" is present in payload and it's valid
        };
      }
      return { ...state };
    case atypes.UPLOAD_PROFILE_PICTURE_FILE:
      return {
        ...state,
        ...payload,
        uploadingPhoto: true
      };
    case atypes.SIGNED_IN:
      if (R.not(R.isEmpty(payload))) {
        const {
          uid,
          displayName,
          email,
          updateProfile,
          photoURL,
          emailVerified,
          phoneNumber
        } = payload;
        return {
          ...state,
          uid,
          displayName,
          email,
          updateProfile,
          photoURL,
          emailVerified,
          phoneNumber
        };
      }
      return { ...payload };
    case atypes.EMAIL_VERIFIED:
      return {
        ...state,
        emailVerified: true
      };
    case atypes.SIGNED_OUT:
      return initialState;
    case atypes.API_AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true
      };
    case atypes.SERVER_AUTH_SUCCESS:
      return {
        ...state,
        phoneNumber: payload.phoneNumber,
        department: payload.department
      };
    case atypes.API_AUTH_FAILURE:
      return {
        ...state,
        authenticated: false
      };
    case atypes.UPLOAD_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        photoURL: payload.photoUrl,
        uploadingPhoto: false
      };
    case atypes.UPLOAD_PROFILE_PHOTO_FAILURE:
      return {
        ...state,
        uploadingPhoto: false
      };
    case atypes.UPLOAD_PROFILE_PICTURE_FILE_FAILURE:
      return {
        ...state,
        uploadingPhoto: false
      };
    case atypes.UPDATE_DISPLAY_NAME_SUCCESS: {
      const { displayName } = payload;
      return {
        ...state,
        displayName
      };
    }
    case atypes.SHOW_UPDATE_NAME_LOADING:
      return { ...state, updatingName: true };
    case atypes.HIDE_UPDATE_NAME_LOADING:
      return { ...state, updatingName: false };
    case atypes.SRW_SIGNED_IN: {
      const { displayName, email, uid, emailVerified } = payload;
      return { ...state, displayName, email, uid, emailVerified };
    }
    default:
      return state;
  }
};

export default currentUser;

export const isCurrentUserUnifizeEmployee = (state: UnifizeUser): boolean =>
  state.email.endsWith("@unifize.com");
