// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
`;

const Label = styled.label`
  font-size: 14px;
  width: fit-content;
  font-weight: ${({ weight }) => weight};
`;

const SelectedContent = styled.div`
  display: inline-flex;
  position: relative;
  border: 1px solid ${colors.grey35};
  min-height: 2rem;
  max-height: 6rem;
  overflow-y: auto;
  border-radius: ${radius.medium};
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 3px;
  background-color: white;
`;

const RowContent = styled.div`
  min-height: 2rem;
  height: ${props => {
    const rowCount = Math.ceil(props.selected.length / props.itemsPerRow) + 1;
    return rowCount <= 1 ? "32px" : rowCount === 2 ? "64px" : "96px";
  }};
  max-height: 6rem;
  position: relative;
`;

const SelectedRow = styled.div`
  height: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4px;
  gap: 7px;
`;

const SelectedRowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  border-radius: 2px;
  max-width: 132px;
  background-color: ${colors.grey10};
  padding: 0 0.25rem;
  font-size: 12px;
  &:hover {
    background-color: ${colors.grey2};
  }

  cursor: pointer;
`;

const RowClearButton = styled.button`
  height: 24px;
  position: relative;
  right: 12px;
  top: 4px;
  min-width: 24px;
  border-radius: 4px;
  font-size: 12px;
  color: ${colors.active};
  background: #f3f8ff;
  &:hover {
    background-color: f3f8ff;
  }
`;

const ClearAllWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: sticky;
  float: right;
  top: 0;
  right: 0;
`;

const SelectedItems = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: ${props => (props.showSelectAll ? "90%" : "100%")};
  gap: 8px;
`;

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  gap: 4px;
  background-color: ${colors.grey10};
  padding: 0 0.25rem;
  font-size: 12px;
  &:hover {
    background-color: ${colors.grey2};
  }
  cursor: pointer;
`;

const SelectedLabel = styled.span`
  max-width: 156px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RemoveButton = styled.span`
  padding: 0 0.25rem;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.125rem;
  flex-grow: 1;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  height: 1.5rem;
  font-size: 14px;
  ::placeholder {
    font-size: 14px;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  margin-top: 0.25rem;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  padding: 0;
  z-index: 10;
`;

const DropdownList = styled.div`
  max-height: 12rem;
  overflow-y: auto;
`;

const DropdownItem = styled.div`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  &.highlighted {
    background-color: ${colors.grey10};
  }
  &.selected {
    font-weight: bold;
    background-color: ${colors.grey10};
  }
`;

const SelectAllWrapper = styled.div`
  padding: 0.5rem 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ToggleButton = styled.button`
  height: 24px;
  min-width: 24px;
  border-radius: 4px;
  font-size: 12px;
  color: ${colors.active};
  background: #f3f8ff;
  &:hover {
    background-color: f3f8ff;
  }
`;

const DropdownText = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const ItemTitle = styled.span`
  max-width: 100%;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ color }) => color || "inherit"};
`;

const ItemSubTitle = styled.span`
  font-size: 10px;
  color: ${colors.grey5};
`;

export {
  Container,
  Selection,
  Label,
  SelectedContent,
  SelectedItems,
  SelectedItem,
  RemoveButton,
  InputContainer,
  Input,
  DropdownMenu,
  DropdownList,
  DropdownItem,
  ToggleButton,
  DropdownText,
  ItemTitle,
  ItemSubTitle,
  SelectAllWrapper,
  ClearAllWrapper,
  SelectedLabel,
  SelectedRow,
  RowContent,
  SelectedRowItem,
  RowClearButton
};
