// @flow

import { useSelector } from "react-redux";
import React, { useCallback, useRef, useState } from "react";

import Dropdown from "./Dropdown";
import { Select as StyledSelect } from "src/components/Manage/Reports/Instances/Chart/New/FieldSelect/styles";
import Selection from "./Selection";
import useBoolean from "src/hooks/useBoolean";
import { TextWithputBorders as Search } from "src/styles/input";
import { getAllChartIds } from "src/reducers";

type Props = {
  value: ?number,
  handleSelect: Function
};

const FieldSelect = ({ value, handleSelect }: Props) => {
  const outerRef = useRef(null);
  const { value: dropdown, setTrue, setFalse } = useBoolean();
  const charts = useSelector(({ app }) => getAllChartIds(app));
  const [filteredCharts, setFilteredCharts] = useState(charts);

  const handleFocus = useCallback(() => {
    setTrue();
  }, [setTrue]);

  const handleSelection = useCallback(
    (newValue: any) => {
      setFalse();
      handleSelect(newValue);
    },
    [handleSelect, setFalse]
  );

  const handleSearch = useCallback((event: any) => {
    const query = event.target.value;
    const filtered = filteredCharts.filter(chart => {
      if (chart?.title) {
        return chart.title.toLowerCase().includes(query.toLowerCase());
      }
      return false;
    });
    setFilteredCharts(filtered);
  }, []);

  return (
    <StyledSelect ref={outerRef}>
      {dropdown && (
        <Dropdown
          outerRef={outerRef}
          handleSelect={handleSelection}
          handleClose={setFalse}
          value={filteredCharts}
        />
      )}

      {!value || dropdown ? (
        <Search
          type="text"
          placeholder="Select field"
          onChange={handleSearch}
          autoFocus={dropdown}
          onKeyPress={handleFocus}
          onClick={handleFocus}
        />
      ) : (
        <Selection value={value} handleSelect={handleSelect} />
      )}
    </StyledSelect>
  );
};

export default FieldSelect;
