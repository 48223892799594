// @flow
import React from "react";
import type { Node } from "react";

import type { UseBooleanReturn } from "src/types";
import * as styles from "./styles";
import Icon from "src/icons";

type Props = {
  children?: Node,
  customStyles?: { [string]: string | number },
  iconColor?: string,
  closeModal: $PropertyType<UseBooleanReturn, "setFalse">,
  dataCy?: string;
};

const ModalClose = ({
  children,
  customStyles,
  iconColor,
  closeModal,
  dataCy
}: Props) => {
  return (
    <div style={{ ...styles.ModalClose, ...customStyles }} onClick={closeModal}>
      {children ? (
        children
      ) : (
        <Icon
          data-cy={dataCy}
          size="1rem"
          type="close"
          cursor="pointer"
          color={iconColor ? iconColor : "#000"}
        />
      )}
    </div>
  );
};

export default ModalClose;
