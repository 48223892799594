// @flow

import React from "react";
import { Container, Input, Label } from "./styles";

type Props = {
  placeholder?: string,
  onChange: Function,
  value: string | number,
  label?: string,
  labelWeight?: "normal" | "bold"
};

const TextInput = ({
  value,
  placeholder = "",
  onChange,
  label = "",
  labelWeight = "normal",
  ...restProps
}: Props) => {
  return (
    <Container>
      <Label weight={labelWeight}>{label}</Label>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...restProps}
      />
    </Container>
  );
};

export default TextInput;
