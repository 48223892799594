// @flow

import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { approvalFieldStatuses } from "src/constants";
import { VStack } from "@chakra-ui/react";

import CancelButton from "./CancelButton";
import SignatureButtonGroup from "./SignatureButtonGroup";
import RequestApprovalButton from "./RequestApprovalButton";
import useFields from "../useFields";
import ApprovalRevisions from "./ApprovalRevisions";
import { getDisabledState, getLastApprovalSignatureCount } from "./utils";

import { setChecklistValue } from "src/actions/checklist";
import {
  getAllowedSignatories,
  getCurrentUserId,
  getAllowedCancellers,
  getAllSignatories,
  getRoomFieldValueStatus,
  getLockedStatus,
  getChecklistValue,
  getChecklistFieldDetails,
  getFormFieldValueStatus,
  getChecklistFormValue,
  getIsFieldLocked
} from "src/reducers";

import * as styles from "./styles";
import type {
  HttpMethods,
  ApprovalFieldSettingsV2,
  FieldId,
  RoomId,
  ChecklistValue,
  ApprovalFieldValue
} from "src/types";
import { getMinApprovers } from "src/utils/checklist";
import { dataStages } from "src/constants";

export type HandleCreateRevision = (comment: ?string) => void;

type Props = {
  fieldId: FieldId,
  roomId: RoomId,
  formId: ?number,
  roomFieldFormId?: ?string
};

export default function ApprovalV2({
  fieldId,
  roomId,
  formId,
  roomFieldFormId
}: Props) {
  const dispatch = useDispatch();

  const checklistFieldValue: ChecklistValue & {
    value: ApprovalFieldValue
  } = useSelector(({ app }) => getChecklistValue(app, fieldId, roomId));
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const checklistFieldValueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, roomId)
  );
  const formFieldValueStatus = useSelector(({ app }) =>
    getFormFieldValueStatus(app, fieldId, formId)
  );
  const valueStatus = formId ? formFieldValueStatus : checklistFieldValueStatus;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const { settings }: { settings: ApprovalFieldSettingsV2 } = useFields({
    checklistValue,
    details
  });

  const updating = valueStatus === dataStages.updating;
  const { value } = checklistValue || {
    value: {
      lockChecklistFields: false,
      adhocConfig: null,
      config: null,
      completed: false,
      revisions: [],
      status: "not-started"
    }
  };

  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));
  const requiredSignatures = getMinApprovers(settings, value);
  const allowedSignatories = useSelector(({ app }) =>
    getAllowedSignatories(app, fieldId, roomId)
  );

  const allSignatories = useSelector(state =>
    getAllSignatories(state.app, fieldId, roomId)
  );
  const allowedCancellers = useSelector(({ app }) =>
    getAllowedCancellers(app, fieldId, roomId)
  );

  const canCurrentUserCancel = allowedCancellers.includes(currentUserId);

  const disabled = getDisabledState({
    value,
    allowedSignatories,
    canCurrentUserCancel,
    locked,
    currentUserId
  });

  const setFieldValue = useCallback(
    ({
      value,
      httpMethod,
      extraBody
    }: {
      value: any,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      dispatch(
        setChecklistValue({
          roomId,
          id: fieldId,
          value: {
            value,
            type: "approval",
            checked: true
          },
          progress: true,
          formId,
          httpMethod,
          extraBody
        })
      );
    }
  );

  const lastApprovalSignatureCount = getLastApprovalSignatureCount(value);

  const handleCreateRevision: HandleCreateRevision = comment => {
    let params = {
      revision: true
    };

    if (comment) {
      params = { ...params, comment };
    }

    setFieldValue({ value: params });
  };

  if (value.status === approvalFieldStatuses.notStarted) {
    if (settings.showRequestApprovalButton)
      return (
        <RequestApprovalButton
          settings={settings}
          setFieldValue={setFieldValue}
          disabled={locked}
          signatories={allSignatories}
          updating={updating}
        />
      );

    return (
      <VStack sx={styles.container}>
        <SignatureButtonGroup
          bordered
          hasUserSigned={false}
          done={0}
          total={requiredSignatures}
          handleSign={setFieldValue}
          disabled={disabled}
          signatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          settings={settings}
        />

        {settings.canCancelPartialApproval &&
          settings.showRequestApprovalButton && (
            <CancelButton
              createRevision={handleCreateRevision}
              disabled={locked || !canCurrentUserCancel}
              updating={updating}
              isCommentRequired={settings.requireCancellationComment}
              signatureCount={lastApprovalSignatureCount}
              roomId={roomId}
              fieldId={fieldId}
              settings={settings}
            />
          )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.started) {
    return (
      <VStack sx={styles.container}>
        <ApprovalRevisions
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.canCancelPartialApproval && (
          <CancelButton
            createRevision={handleCreateRevision}
            disabled={locked || !canCurrentUserCancel}
            updating={updating}
            isCommentRequired={settings.requireCancellationComment}
            signatureCount={lastApprovalSignatureCount}
            roomId={roomId}
            fieldId={fieldId}
            settings={settings}
          />
        )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.approved) {
    return (
      <VStack sx={styles.container}>
        <ApprovalRevisions
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.allowCancelOnApproval && (
          <CancelButton
            createRevision={handleCreateRevision}
            disabled={locked || !canCurrentUserCancel}
            updating={updating}
            isCommentRequired={settings.requireCancellationComment}
            signatureCount={lastApprovalSignatureCount}
            roomId={roomId}
            fieldId={fieldId}
            settings={settings}
          />
        )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.rejected) {
    return (
      <VStack sx={styles.container}>
        <ApprovalRevisions
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.showRequestApprovalButton ? (
          <RequestApprovalButton
            settings={settings}
            setFieldValue={setFieldValue}
            disabled={locked}
            signatories={allSignatories}
            updating={updating}
          />
        ) : (
          <>
            <SignatureButtonGroup
              bordered
              hasUserSigned={false}
              done={0}
              total={requiredSignatures}
              handleSign={setFieldValue}
              disabled={disabled}
              signatories={allowedSignatories}
              roomId={roomId}
              fieldId={fieldId}
              settings={settings}
            />

            {settings.canCancelPartialApproval &&
              settings.showRequestApprovalButton && (
                <CancelButton
                  createRevision={handleCreateRevision}
                  disabled={locked || !canCurrentUserCancel}
                  updating={updating}
                  isCommentRequired={settings.requireCancellationComment}
                  signatureCount={lastApprovalSignatureCount}
                  roomId={roomId}
                  fieldId={fieldId}
                  settings={settings}
                />
              )}
          </>
        )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.cancelled) {
    return (
      <VStack sx={styles.container}>
        <ApprovalRevisions
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.showRequestApprovalButton && (
          <RequestApprovalButton
            settings={settings}
            setFieldValue={setFieldValue}
            disabled={locked}
            signatories={allSignatories}
            updating={updating}
          />
        )}
      </VStack>
    );
  }

  return null;
}
