// @flow

import React, { useEffect } from "react";
import * as R from "ramda";
import { Flex, FormLabel, Text, Input } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

import { getAllRoles } from "src/actions/roleManagement";
import { MultiSelect } from "src/components/Unifize";
import Select from "src/components/Select";
import { styles } from "../styles";

import { getRoleTitles } from "src/reducers";

type Props = {
  value: Array<number>,
  options: Array<string>,
  updateCondition: Function,
  blockIndex: number,
  conditionIndex: number
};

const PickList = ({
  value,
  options,
  updateCondition,
  blockIndex,
  conditionIndex
}: Props) => {
  const handleChange = option =>
    updateCondition(
      {
        value: R.includes(option, value)
          ? R.reject(R.equals(option), value)
          : R.uniq([...value, option])
      },
      blockIndex,
      conditionIndex,
      false
    );

  const handleClearAll = option => {
    updateCondition(
      {
        value: []
      },
      blockIndex,
      conditionIndex,
      false
    );
  };

  const handleSelectAll = () => {
    updateCondition({ value: options }, blockIndex, conditionIndex, false);
  };

  return (
    <Flex {...styles.qualifierContainer}>
      <FormLabel {...styles.label}>Value</FormLabel>
      <Select
        value={value}
        placeholder="Value"
        onChange={handleChange}
        onSelectAll={handleSelectAll}
        onClearAll={handleClearAll}
        itemPredicate={({ item, inputValue }) =>
          item.props.value.toLowerCase().includes(inputValue.toLowerCase())
        }
        multiple
      >
        {/* // $FlowFixMe */}
        {options?.map(option => (
          <Text key={option} value={option} variant="ink400" label={option}>
            {option}
          </Text>
        ))}
      </Select>
    </Flex>
  );
};

export default PickList;
