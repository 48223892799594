// @flow

import styled from "@emotion/styled";

import { Header as StyledHeader, Close } from "../styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

export const IconButton = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.brightLight};
  > svg {
    height: 1em;
    width: 1em;
  }
`;

export const ExportIconButton = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.brightLight};
`;

export const CloseButton = styled(IconButton)`
  > svg {
    cursor: pointer !important;
    stroke-width: 2px;
    height: 1em;
    width: 1em;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 0.9em;
  margin-left: 0.7em;
`;

export const Visualization = styled.div`
  min-height: 350px;
  margin-left: 0.5em;
  height: 86%;
`;

export const Header = styled(StyledHeader)`
  background-color: ${colors.brightLight};
  padding: ${spacing.space_inset_l};
  margin: 0;
  ${Close} {
    background-color: ${colors.brightLight};
  }

  ${MenuBar} {
    margin-top: 0.5em;
    margin-left: -0.1em;
  }
`;
