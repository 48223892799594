// @flow

import React, { useEffect } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";

import { getAllRoles } from "src/actions/roleManagement";
import { MultiSelect } from "src/components/Unifize";

import { getRoleTitles } from "src/reducers";

type Props = {
  onChange: Function,
  value: Array<number>
};

const Role = ({ onChange, value }: Props) => {
  const dispatch = useDispatch();
  const roles = useSelector(({ app }) => getRoleTitles(app));

  useEffect(() => {
    if (R.isEmpty(roles)) {
      dispatch(getAllRoles());
    }
  }, []);

  return (
    <MultiSelect
      label="Value"
      labelWeight="bold"
      placeholder="Search"
      data={roles}
      selected={value}
      keys={["id", "label"]}
      onChange={onChange}
      showCheckBox
      showSelectAll
    />
  );
};

export default Role;
