// @flow

import React from "react";
import { Avatar, Checkbox } from "src/components/Unifize";
import {
  DropdownItem,
  DropdownList,
  DropdownText,
  ItemTitle,
  ItemSubTitle
} from "./styles";

type Props = {
  items: Array<Object>,
  keys: Array<string>,
  highlightedIndex: number,
  getItemProps: Function,
  selected: Array<any>,
  showIcon: boolean,
  showCheckBox: boolean
};

const Dropdown = ({
  items,
  keys,
  highlightedIndex,
  getItemProps,
  showIcon,
  selected,
  showCheckBox
}: Props) => {
  const id: string = keys[0];
  const display: string = keys[1];
  const description: string = keys[2] || "";
  const url: string = keys[3] || "";

  return (
    <DropdownList>
      {items.map((item, index) => (
        <DropdownItem
          key={`${item[id]}${index}`}
          className={highlightedIndex === index && "highlighted"}
          {...getItemProps({ item, index })}
        >
          {showIcon && <Avatar size="lg" src={item[url]} alt={item[display]} />}
          {showCheckBox && (
            <Checkbox
              checked={selected.includes(item[id])}
              onChange={() => null}
            />
          )}
          <DropdownText>
            <ItemTitle>{item[display]}</ItemTitle>
            {description && <ItemSubTitle>{item[description]}</ItemSubTitle>}
          </DropdownText>
        </DropdownItem>
      ))}
    </DropdownList>
  );
};

export default Dropdown;
