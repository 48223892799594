// @flow

import React from "react";
import { TextInput } from "src/components/Unifize";

type Props = {
  onChange: Function,
  value: number
};

const Number = ({ onChange, value }: Props) => {
  return (
    <TextInput
      label="Value"
      labelWeight="bold"
      placeholder="Enter a value"
      value={value}
      onChange={onChange}
      type="number"
    />
  );
};

export default Number;
