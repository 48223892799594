// @flow

import React from "react";
import * as R from "ramda";

import Conversation from "./Conversation";
import Group from "./Group";
import Role from "./Role";
import Status from "./Status";
import User from "./User";
import Number from "./Number";
import PickList from "./PickList";

import { fields } from "src/conditions";

type Props = {
  type: ?string,
  checklistFieldType: ?string,
  selectedFieldType: string,
  value: any,
  fieldSettings: Object,
  updateCondition: Function,
  blockIndex: number,
  conditionIndex: number
};

const ValueDropdown = ({
  type,
  checklistFieldType,
  selectedFieldType,
  value,
  fieldSettings,
  updateCondition,
  blockIndex,
  conditionIndex
}: Props) => {
  const showUserField =
    R.includes(selectedFieldType, [fields.owner, fields.currentUser, "user"]) &&
    R.includes(type, ["isAnyOf", "isNoneOf"]);

  const showConversationField =
    R.includes(checklistFieldType, [
      "conversation",
      "childConversation",
      "link"
    ]) && R.includes(type, ["isAnyOf", "isNoneOf"]);

  const showNumberField =
    checklistFieldType === "number" &&
    R.includes(type, ["isEqualTo", "isLessThan", "isGreaterThan"]);

  const showSelectField =
    checklistFieldType === "select" &&
    R.includes(type, ["isAnyOf", "isNoneOf"]);

  const showRoleField =
    selectedFieldType === fields.currentUser && R.includes("Role", type || "");

  const showGroupField = type === "isPartOf";

  const showStatusField = selectedFieldType === "Status";

  const handleSelect = value =>
    updateCondition(
      {
        value
      },
      blockIndex,
      conditionIndex,
      false
    );

  const handleChange = e =>
    updateCondition(
      { value: parseInt(e.target.value) },
      blockIndex,
      conditionIndex,
      false
    );

  return (
    <>
      {showRoleField && <Role value={value} onChange={handleSelect} />}
      {showGroupField && <Group value={value} onChange={handleSelect} />}
      {showStatusField && <Status value={value} onChange={handleSelect} />}
      {showConversationField && (
        <Conversation
          onChange={handleSelect}
          value={value}
          type={fieldSettings.type}
          workflow={fieldSettings.workflow}
          selectedItemsPerRow={3}
        />
      )}
      {showUserField && <User value={value} onChange={handleSelect} />}
      {showNumberField && <Number value={value} onChange={handleChange} />}
      {showSelectField && (
        <PickList
          value={value}
          updateCondition={updateCondition}
          options={fieldSettings?.options}
          blockIndex={blockIndex}
          conditionIndex={conditionIndex}
        />
      )}
    </>
  );
};

export default ValueDropdown;
