// @flow

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { searchChatroom } from "src/actions/chatroom";
import { getChatroomSelectData } from "src/reducers";
import { MultiSelect } from "src/components/Unifize";

import type { WorkflowId, RoomId } from "src/types";

type Props = {
  onChange: Function,
  value: Array<RoomId>,
  type: string,
  workflow: ?WorkflowId,
  selectedItemsPerRow: number
};

const Conversation = ({
  onChange,
  value,
  type,
  workflow,
  selectedItemsPerRow
}: Props) => {
  const filters = { type, workflow };
  const dispatch = useDispatch();
  const chatRooms = useSelector(({ app }) =>
    getChatroomSelectData(app, filters)
  );

  return (
    <MultiSelect
      virtualized
      label="Value"
      labelWeight="bold"
      placeholder="Search"
      data={chatRooms}
      keys={["id", "title"]}
      selected={value}
      onChange={onChange}
      showSelectAll
      showCheckBox
      itemsPerRow={selectedItemsPerRow}
    />
  );
};

export default Conversation;
