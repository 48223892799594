// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: ${spacing.space_xs};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  font-size: 14px;
  width: fit-content;
  font-weight: ${({ weight }) => weight};
`;

const Input = styled.input`
  border: 1px solid ${colors.grey35};
  width: 100%;
  height: 2rem;
  border-radius: ${radius.medium};
  padding: 0.375rem;
  background-color: white;
  font-size: 14px;
`;

export { Container, Label, Input };
