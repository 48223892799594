// @flow

import { connect } from "react-redux";
import React, { useCallback, useRef } from "react";

import { Chart as StyledChart, Heading, MenuBar } from "./styles";
import Icon from "src/icons";
import RefreshButton from "src/components/chart/RefreshButton";
import Visualization from "src/components/Manage/Reports/Instances/Chart/New/Chart/Visualization";
import {
  getPrincipalChecklistFieldNames,
  getReport,
  getChartData,
  getChartDataLoading
} from "src/reducers";
import { showView } from "src/actions";
import { setNewChartDialogAttributes } from "src/actions/chart";
import { deleteChartFromDashboard } from "src/actions/dashboard";
import { setReports } from "src/actions/reports";
import { exportChart } from "src/utils/charts";

import type { AppState, Report } from "src/types";

type Props = {
  title: string,
  typeId: number,
  description: string,
  chartId: number,
  dashboard: number,
  yAxisLabel: string,
  type: number,
  x: number,
  y: ?number,
  fieldsById: Object,
  metaData: string,
  reportId: number,
  report: Report,
  isChartLoading: boolean,
  _deleteChartFromDashboard: Function,
  _setReports: typeof setReports,
  _showView: Function,
  _getChartJSON: Function,
  _setNewChartDialogAttributes: Function
};

const Chart = ({
  typeId,
  reportId,
  title,
  chartId,
  dashboard,
  type,
  description,
  x,
  y,
  yAxisLabel,
  report,
  metaData,
  isChartLoading,
  _deleteChartFromDashboard,
  _setNewChartDialogAttributes,
  _setReports,
  _showView
}: Props) => {
  const chartRef = useRef(null);

  const handleReport = useCallback(() => {
    _showView("manage");
    _setNewChartDialogAttributes({
      title,
      type: typeId,
      description,
      loading: false,
      error: null,
      show: false,
      stage: 2,
      id: chartId,
      field1: x,
      field2: y,
      yAxisLabel
    });
    _setReports(reportId, {
      query: {
        chartId
      }
    });
  }, [
    title,
    typeId,
    description,
    chartId,
    reportId,
    setReports,
    x,
    y,
    yAxisLabel,
    report,
    _setNewChartDialogAttributes,
    _showView
  ]);

  return (
    <StyledChart>
      <Heading>
        <MenuBar>
          {!isChartLoading && <RefreshButton chartId={`${chartId}`} />}
          <button
            title="Export"
            type="button"
            onClick={() => exportChart(chartRef)}
          >
            <Icon type="export" />
          </button>
          <button
            type="button"
            title="Remove"
            onClick={() =>
              _deleteChartFromDashboard({
                chart: chartId,
                dashboard
              })
            }
          >
            <Icon type="deleteTrash" size={17} />
          </button>

          <button title="Expand" type="button" onClick={handleReport}>
            <Icon type="fullscreen" size={16} />
          </button>
        </MenuBar>
      </Heading>

      <Visualization
        title={title}
        chartId={chartId}
        type={type}
        height="400"
        width="98%"
        chartData={metaData}
        chartRef={chartRef}
      />
    </StyledChart>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  fieldsById: getPrincipalChecklistFieldNames(app),
  report: getReport(app, props.reportId),
  metaData: getChartData(app, props.chartId),
  isChartLoading: getChartDataLoading(app, props.chartId)
});

export default connect(mapStateToProps, {
  _deleteChartFromDashboard: deleteChartFromDashboard,
  _setReports: setReports,
  _showView: showView,
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(Chart);
