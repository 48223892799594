// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getAllActiveUsers } from "src/reducers";
import { MultiSelect } from "src/components/Unifize";

import type { UserMention } from "src/types";

type Props = {
  onChange: Function,
  value: Array<number>
};

const User = ({ onChange, value }: Props) => {
  const users: Array<UserMention> = useSelector(({ app }) =>
    getAllActiveUsers(app)
  );

  return (
    <MultiSelect
      showIcon
      label="Value"
      labelWeight="bold"
      placeholder="Search"
      data={users}
      selected={value}
      keys={["id", "name", "email", "photoUrl"]}
      onChange={onChange}
      showSelectAll
    />
  );
};

export default User;
