// @flow

import firebase from "firebase/app";
import db from "src/db";

import { backendUrl } from "src/config/firebase";
import type { UID } from "src/types";

/**
 * Returns the organization id which the user earlier had
 * visited.
 *
 * @param {CurrentUnifizeUser} - Current Unifize Object.
 * @return {Number} - It returns the id of the organization
 * earlier visited.
 */
const getId = async ({ uid }: { uid: string }) => {
  const snapshot = await db()
    .collection(`userData/${uid}/userData`)
    .doc("orgs")
    .get();
  const data = snapshot.data();
  return data ? data.last : null;
};

/**
 * Returns all the organization ids the user belongs to
 * visited.
 *
 * @param {CurrentUnifizeUser} - Current Unifize Object.
 * @return {Array} - It returns the array of organizations the
 * current user belongs to.
 */
const getAll = async (uid: UID) => {
  const snapshot = await db().collection("userData").doc(uid).get();
  const { orgs = [] } = snapshot.data() || {};
  return orgs;
};

/**
 * Returns Org detail for the given org id
 *
 * @param {number} - orgId - org id.
 * @return {OrgDetails} - org details
 */
const getDetails = async ({ orgId }: { orgId: number }) => {
  const response = await fetch(`${backendUrl}/org/${orgId}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response.json();
};

/**
 * Sets last org for the user
 *
 * @param { number } - orgId: org id
 * @param { string } - UID: uid of the current user
 * @returns { number } - orgId: org id that was set
 */
const setLastOrg = async ({ orgId, uid }: { orgId: number, uid: UID }) => {
  const org = db().collection(`userData/${uid}/appData`).doc("settings");

  try {
    await org.update({ lastOrg: orgId });
  } catch (e) {
    console.error("Can't redirect: ", e);
    // Settings do not exists, create the document
    await org.set({ lastOrg: orgId });
  }
};

/**
 * Fetches last org for the user
 *
 * @param {string} - UID: uid of the current user
 * @returns {number} - The id of last org user visited
 */
const getLastOrg = async (uid: UID) => {
  const settings = await db()
    .collection(`userData/${uid}/appData`)
    .doc("settings")
    .get();

  if (settings.exists) {
    const { lastOrg } = settings.data();
    return lastOrg;
  }
};

/**
 * Fetches details of org that user belongs to
 *
 * @returns {Array<OrgDetails>} - Details of orgs that user belongs to
 */
const getUserOrgDetails = async () => {
  const response = await fetch(`${backendUrl}/org`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response.json();
};

/**
 * Delete last org for the user
 *
 * @param {string} - UID: uid of the current user
 */
const deleteLastOrg = async (uid: UID) => {
  const org = db().collection(`userData/${uid}/appData`).doc("settings");

  await org.update({
    lastOrg: firebase.firestore.FieldValue.delete()
  });
};

export {
  getId,
  getAll,
  getDetails,
  setLastOrg,
  getLastOrg,
  getUserOrgDetails,
  deleteLastOrg
};
