// @flow

import * as R from "ramda";
import { matchesChatroomTitle } from "src/utils/filters";
import { priorityPrecedenceMap } from "src/constants/priority";
import moment from "moment";

import type {
  FieldId,
  RoomId,
  ColumnId,
  UID,
  UnifizeChatRoomById,
  UnifizeUser,
  Group
} from "src/types";

/**
 * Filters embedded chatroom attributes
 * @param {string} columnId - column we are filtering by.
 * @param {Object} parentFieldValue - value of the parent field.
 * @return {string[] | number[]} - extracted value of embedded chatroom attributes
 */
const extractEmbeddedAttributeValues = (
  columnId: string,
  parentFieldValue: any
) => {
  const values = [];
  Object.keys((parentFieldValue || {}).entities?.chatrooms || {}).map(
    chatroomId => {
      const chatrooms = parentFieldValue?.entities?.chatrooms || {};
      if (chatroomId in chatrooms)
        values.push(
          chatrooms[chatroomId].chatroom[`${columnId}`.split("-")[1]]
        );
    }
  );
  return values;
};

/**
 * Filters by blanks & non-blanks aplicable for fields such as picklist
 * due date, linked field, conversation, all user related filters
 * (owner, participants etc)
 * @param {*[]} fieldValue - value of the field we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
export const filterByBlanksAndNonBlanks = (
  fieldValue: Array<any>,
  filterValue: Array<string>,
  isEmbeddedField?: boolean
) => {
  if (filterValue.includes("null") && filterValue.includes("notnull")) {
    return true;
  } else if (filterValue.includes("null")) {
    if (R.isEmpty(fieldValue) && isEmbeddedField) {
      return false;
    }
    return (
      R.isEmpty(fieldValue) ||
      R.isNil(fieldValue) ||
      fieldValue[0] === "Invalid date"
    );
  } else if (filterValue.includes("notnull")) {
    return !R.isEmpty(fieldValue) && !R.isNil(fieldValue);
  }

  return false;
};

/**
 * Implements filtering by value for fields select, text, number & file
 * @param {Object} row - an instance of a process.
 * @param {number} columnId - column (fieldId) we're filtering by.
 *    Will only have one element which will be the fieldId.
 * @param {string[]} filterValue - column's filter state.
 */
const fieldValueFilter = (
  row: Object,
  columnId: number,
  filterValue: Array<string>
): boolean => {
  const fieldValue = row.getValue(columnId);
  const isEmbeddedField = `${columnId}`.includes("-");

  if (Array.isArray(fieldValue) && isEmbeddedField) {
    const parentInstanceResult = R.any(value => {
      const result =
        filterValue.length === 0 || row.original?.isExpandedSubrow
          ? true
          : (value || []).length == 0
            ? false
            : R.intersection(filterValue, value || []).length > 0;
      return result || filterByBlanksAndNonBlanks(value || [], filterValue);
    }, fieldValue || []);
    return parentInstanceResult;
  }
  return filterValue.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.intersection(filterValue, fieldValue || []).length > 0 ||
        filterByBlanksAndNonBlanks(fieldValue, filterValue);
};

export const dateRangeFilters = [
  "current-week-to-date",
  "last-week",
  "current-month-to-date",
  "last-month",
  "current-quarter-to-date",
  "last-quarter",
  "current-year-to-date",
  "last-year"
];

export const dateRangeToLabelMap = {
  "current-week-to-date": "Current week to-date",
  "last-week": "Last week",
  "current-month-to-date": "Current month to-date",
  "last-month": "Last month",
  "current-quarter-to-date": "Current quarter to-date",
  "last-quarter": "Last quarter",
  "current-year-to-date": "Current year to-date",
  "last-year": "Last year"
};

/**
 * From string array filters and returns only valid dates
 * @param {string[]} filter - column's filter state.
 */
const getValidDates = (filter: Array<string>) => {
  return R.filter(
    item =>
      !["null", "notnull", ...dateRangeFilters].includes(item) ||
      !item.startsWith("lastn") ||
      !item.startsWith("nextn"),
    filter
  );
};

/**
 * Determines if the given date value falls within the range of the current week.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInCurrentWeek = (value: ?Date | Array<Date>): boolean => {
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfWeek, endOfWeek),
      value
    );
  }
  return moment(value).isBetween(startOfWeek, endOfWeek);
};

/**
 * Determines if the given date value falls within the range of the previous week.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInLastWeek = (value: ?Date | Array<Date>): boolean => {
  const startOfWeek = moment().subtract(1, "week").startOf("week");
  const endOfWeek = moment().subtract(1, "week").endOf("week");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfWeek, endOfWeek),
      value
    );
  }
  return moment(value).isBetween(startOfWeek, endOfWeek);
};

/**
 * Determines if the given date value falls within the range of the current month to date.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInCurrentMonth = (value: ?Date | Array<Date>): boolean => {
  const startOfMonth = moment()
    .startOf("month")
    .subtract(1, "day")
    .startOf("day");
  const endOfMonth = moment().endOf("day");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfMonth, endOfMonth),
      value
    );
  }
  return moment(value).isBetween(startOfMonth, endOfMonth);
};

/**
 * Determines if the given date value falls within the range of the previous month.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInLastMonth = (value: ?Date | Array<Date>): boolean => {
  const startOfMonth = moment()
    .subtract(1, "month")
    .startOf("month")
    .subtract(1, "day")
    .startOf("day");
  const endOfMonth = moment().subtract(1, "month").endOf("month");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfMonth, endOfMonth),
      value
    );
  }
  return moment(value).isBetween(startOfMonth, endOfMonth);
};

/**
 * Determines if the given date value falls within the range of the current quarter to date.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInCurrentQuarter = (value: ?Date | Array<Date>): boolean => {
  const startOfQuarter = moment()
    .startOf("quarter")
    .subtract(1, "day")
    .startOf("day");
  const endOfQuarter = moment().endOf("day");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfQuarter, endOfQuarter),
      value
    );
  }
  return moment(value).isBetween(startOfQuarter, endOfQuarter);
};

/**
 * Determines if the given date value falls within the range of the previous quarter.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInLastQuarter = (value: ?Date | Array<Date>): boolean => {
  const startOfQuarter = moment()
    .subtract(1, "quarter")
    .startOf("quarter")
    .subtract(1, "day")
    .startOf("day");
  const endOfQuarter = moment().subtract(1, "quarter").endOf("quarter");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfQuarter, endOfQuarter),
      value
    );
  }
  return moment(value).isBetween(startOfQuarter, endOfQuarter);
};

/**
 * Determines if the given date value falls within the range of the current year to date.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInCurrentYear = (value: ?Date | Array<Date>): boolean => {
  const startOfYear = moment()
    .startOf("year")
    .subtract(1, "day")
    .startOf("day");
  const endOfYear = moment().endOf("day");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfYear, endOfYear),
      value
    );
  }
  return moment(value).isBetween(startOfYear, endOfYear);
};

/**
 * Determines if the given date value falls within the range of the previous year.
 * @param {Date | null} value - The date value to check.
 * @return {boolean}
 */
const isDateInLastYear = (value: ?Date | Array<Date>): boolean => {
  const startOfYear = moment()
    .subtract(1, "year")
    .startOf("year")
    .subtract(1, "day")
    .startOf("day");
  const endOfYear = moment().subtract(1, "year").endOf("year");
  if (Array.isArray(value)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isBetween(startOfYear, endOfYear),
      value
    );
  }
  return moment(value).isBetween(startOfYear, endOfYear);
};

/**
 * Returns a boolean indicating whether the given column value falls within the
 * date range specified by the given filter value.
 *
 * @param {any} columnValue - The column value to be checked
 * @param {string} filterValue - The filter value specifying the date range
 * @return {boolean}
 */
const isDateInRange = (columnValue, filterValue) => {
  // Extract the number of days/months/years from the filterValue
  const n = Number(filterValue.split("-")[1]);
  // Extract the unit (days/months/years) and direction (last/first) from the filterValue
  const [direction, , unit] = filterValue.split("-");

  // Calculate the start and end dates of the date range
  let startDate;
  let endDate;
  if (direction === "lastn") {
    startDate = moment().subtract(n, unit);
    endDate = moment();
  } else if (direction === "nextn") {
    startDate = moment();
    endDate = moment().add(n, unit);
  }

  if (Array.isArray(columnValue)) {
    return R.any(
      date =>
        date !== "Invalid date" &&
        moment(date).isSameOrBefore(endDate) &&
        moment(date).isSameOrAfter(startDate),
      columnValue
    );
  }

  // Check if the columnValue falls within the start and end dates
  return (
    moment(columnValue).isSameOrBefore(moment(endDate)) &&
    moment(columnValue).isSameOrAfter(startDate)
  );
};

/**
 * Logic for filtering values in date field
 * @param {any} columnValue - data in the date field
 * @param {string[]} filterValue - column's filter state.
 */
export const dateFilteringLogic = (
  columnValue: any,
  filterValue: string[],
  isEmbeddedField?: boolean
) => {
  const dateValue = filterValue ? getValidDates(filterValue) : null;
  const lowVal = dateValue?.length
    ? R.head(getValidDates(filterValue)[0].split("::"))
    : null;
  const highVal = dateValue?.length
    ? R.last(getValidDates(filterValue)[0].split("::"))
    : null;

  const isColumnValueValid = !columnValue
    ? false
    : Array.isArray(columnValue)
      ? R.any(date => moment(date).format() !== "Invalid date")(columnValue)
      : moment(columnValue).format() !== "Invalid date";

  try {
    if (
      moment(lowVal).format() !== "Invalid date" &&
      moment(highVal).format() !== "Invalid date" &&
      isColumnValueValid
    ) {
      if (Array.isArray(columnValue)) {
        return R.any(
          date =>
            date !== "Invalid date" &&
            moment(date).isBetween(
              moment(lowVal).startOf("day"),
              moment(highVal).endOf("day")
            ),
          columnValue
        );
      }
      return moment(columnValue).isBetween(
        moment(lowVal).startOf("day"),
        moment(highVal).endOf("day")
      );
    } else if (
      moment(lowVal).format() !== "Invalid date" &&
      isColumnValueValid
    ) {
      if (Array.isArray(columnValue)) {
        return R.any(date => {
          return (
            date !== "Invalid date" &&
            moment(date).isSameOrAfter(moment(lowVal).startOf("day"))
          );
        }, columnValue);
      }
      return moment(columnValue).isSameOrAfter(moment(lowVal).startOf("day"));
    } else if (
      moment(highVal).format() !== "Invalid date" &&
      isColumnValueValid
    ) {
      if (Array.isArray(columnValue)) {
        return R.any(
          date =>
            date !== "Invalid date" &&
            moment(date).isSameOrBefore(moment(highVal).endOf("day"))
        )(columnValue);
      }
      return moment(columnValue).isSameOrBefore(moment(highVal).endOf("day"));
    }
  } catch (error) {
    console.error(error);
    return false;
  }

  switch (filterValue[0]) {
    case "current-week-to-date":
      return isDateInCurrentWeek(columnValue);
    case "last-week":
      return isDateInLastWeek(columnValue);
    case "current-month-to-date":
      return isDateInCurrentMonth(columnValue);
    case "last-month":
      return isDateInLastMonth(columnValue);
    case "current-quarter-to-date":
      return isDateInCurrentQuarter(columnValue);
    case "last-quarter":
      return isDateInLastQuarter(columnValue);
    case "current-year-to-date":
      return isDateInCurrentYear(columnValue);
    case "last-year":
      return isDateInLastYear(columnValue);
    default:
      if (
        (filterValue[0] || "").startsWith("lastn") ||
        (filterValue[0] || "").startsWith("nextn")
      ) {
        return isDateInRange(columnValue, filterValue[0]);
      }
  }

  return filterByBlanksAndNonBlanks(columnValue, filterValue, isEmbeddedField);
};

/**
 * Supports filtering by after,due before, blanks & non-blanks on date
 * fields
 * @param {Object} row - an instance of a process.
 * @param {string} columnId - column we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
const dateFilter = (
  row: Object,
  columnId: ColumnId,
  filterValue: Array<string>
): boolean => {
  if (filterValue.length === 0 || row.original?.isExpandedSubrow) {
    return true;
  }

  // Check if it's an embedded due date field
  const parentFieldId = `${columnId}`.includes("-")
    ? `${columnId}`.split("-")[0]
    : false;

  if (parentFieldId) {
    return row.original[parentFieldId]?.result?.length > 0;
  }

  const rawColumnValue = row.original[columnId];
  const columnValue =
    R.type(rawColumnValue) === "object"
      ? moment(rawColumnValue).format()
      : R.type(rawColumnValue) === "Array"
        ? rawColumnValue.map(rawDate => moment(rawDate).format())
        : rawColumnValue;

  return dateFilteringLogic(
    columnValue,
    filterValue,
    `${columnId}`.includes("-")
  );
};

/**
 * Implements filtering by status column
 * @param {Object} row - an instance of a process.
 * @param {string} columnId - column we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
const statusFilter = (
  row: Object,
  columnId: string,
  filterValue: Array<number>
): boolean => {
  const filter = filterValue.filter(item => item !== "includeArchived");
  const isEmbeddedField = `${columnId}`.includes("-");
  const parentColumnId = `${columnId}`.split("-")[0];

  if (isEmbeddedField) {
    let fieldValue = row.original[columnId];
    const parentFieldValue = row.original[parentColumnId];
    if ((parentFieldValue?.result || []).length === 0) {
      return false;
    } else {
      fieldValue = extractEmbeddedAttributeValues(columnId, parentFieldValue);
    }

    return filter.length === 0 || row.original?.isExpandedSubrow
      ? true
      : R.intersection(filter, fieldValue).length > 0 ||
          filterByBlanksAndNonBlanks(
            fieldValue,
            filter.map(item => `${item}`),
            isEmbeddedField
          );
  }

  return filter.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.includes(row.original[columnId], filter);
};

/**
 * Implements filtering by title & all conversation columns
 * @param {Object} row - an instance of a process.
 * @param {string} columnId - column we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
const globalFilter = (
  row: Object,
  columnId: string,
  filterValue: string = "",
  fields: Array<Object>
): boolean => {
  if (filterValue.length === 0) return true;

  // All the conversation fields of the process
  const conversationFields =
    fields.filter(({ type }) =>
      ["link", "conversation", "childConversation"].includes(type)
    ) || [];

  // All the text fields
  const textFields = fields.filter(({ type }) => type === "text");

  const matchesTextFilter = textFields.some(
    ({ id }) =>
      row &&
      id &&
      `${typeof row.original[id] === "string" ? row.original[id] : ""}`
        .toLowerCase()
        .includes(filterValue.toLowerCase())
  );

  if (matchesTextFilter) {
    return true;
  }

  for (let field of conversationFields) {
    const isLinkedField = field.type === "link";
    const chatrooms = isLinkedField
      ? R.values(row.original[field.id]?.entities?.chatrooms || {}).map(
          room => room.chatroom
        ) || []
      : row.original[field.id] || [];

    for (let chatroom of chatrooms) {
      const processTitle = chatroom.processTitle || "";
      const seqNo = chatroom.seqNo || "";
      const title = chatroom.title || "";

      if (matchesChatroomTitle(filterValue, processTitle, seqNo, title)) {
        return true;
      }
    }
  }

  const title = row.getValue(columnId) || "";
  const seqNo = row.getValue("seqNo");

  return matchesChatroomTitle(filterValue, "", seqNo, title);
};

/**
 * Implements filtering by priority column
 * @param {Object} row - an instance of a process.
 * @param {string} columnId - column we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
const priorityFilter = (
  row: Object,
  columnId: number,
  filterValue: Array<number>
): boolean => {
  return filterValue.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.includes(row.original[columnId], filterValue);
};

/**
 * Filters by blanks & non-blanks (Linked field)
 * @param {Object | undefined} fieldValue - value of the field we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
export const filterByBlanksLinkedField = (
  fieldValue: ?{ entities: UnifizeChatRoomById, result: Array<number> },
  filterValue: Array<string>
) => {
  if (filterValue.includes("null") && filterValue.includes("notnull")) {
    return true;
  } else if (filterValue.includes("null")) {
    return (
      (fieldValue && (fieldValue.result || []).length === 0) ||
      R.isNil(fieldValue)
    );
  } else if (filterValue.includes("notnull")) {
    // When fieldValue exists
    if (fieldValue) {
      return (fieldValue.result || []).length > 0;
    }
    return !R.isNil(fieldValue);
  }

  return false;
};

/**
 * Implements filtering by linked field. Supports filtering by single &
 * multiple conversation values
 * @param {Object} row - an instance of a process.
 * @param {number} columnId - column (fieldId) we're filtering by.
 *    Will only have one element which will be the fieldId.
 * @param {string[]} filterValue - column's filter state.
 */
const linkedFieldFilter = (
  row: Object,
  columnId: number,
  filterValue: Array<RoomId>
): boolean => {
  const fieldValue = row.original[columnId];
  if (columnId.toString().includes("-") && fieldValue) {
    return R.any(
      nestedValue =>
        (filterValue.length === 0 || row.original?.isExpandedSubrow
          ? true
          : R.intersection(
              filterValue.map(val => parseInt(val)),
              nestedValue?.result || []
            ).length > 0) ||
        filterByBlanksLinkedField(nestedValue, filterValue),
      fieldValue
    );
  }

  return filterValue.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.intersection(
        filterValue.map(val => parseInt(val)),
        fieldValue?.result || []
      ).length > 0 || filterByBlanksLinkedField(fieldValue, filterValue);
};

/**
 * Implements filtering by conversation. Supports filtering by single &
 * multiple conversation values
 * @param {Object} row - an instance of a process.
 * @param {number} columnId - column (fieldId) we're filtering by.
 *    Will only have one element which will be the fieldId.
 * @param {string[]} filterValue - column's filter state.
 */
const conversationFilter = (
  row: Object,
  columnId: number,
  filterValue: Array<RoomId>
): boolean => {
  const fieldValue = row.original[columnId];
  const isEmbeddedField = `${columnId}`.includes("-");

  if (fieldValue && isEmbeddedField) {
    const parentInstanceResult = R.any(
      value =>
        (filterValue.length === 0 || row.original?.isExpandedSubrow
          ? true
          : R.intersection(
              filterValue,
              (value || []).map(conversation =>
                conversation ? `${conversation.id}` : null
              )
            ).length > 0) || filterByBlanksAndNonBlanks(value, filterValue),
      fieldValue
    );

    return parentInstanceResult;
  }
  return filterValue.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.intersection(
        filterValue.map(roomId => `${roomId}`),
        (row.original[columnId] || []).map(room =>
          Array.isArray()
            ? R.intersection(
                filterValue.map(roomId => `${roomId}`),
                room.map(singleRoom => singleRoom?.id)
              )
            : `${room?.id}`
        )
      ).length > 0 ||
        filterByBlanksAndNonBlanks(row.original[columnId], filterValue);
};

/**
 * Implements filtering by user. Used for filtering: creator, members,
 * completed by & owner
 * @param {Object} row - an instance of a process.
 * @param {string} columnId - column we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
const memberFilter = (
  row: Object,
  columnId: string,
  filterValue: Array<UID>
): boolean => {
  if (filterValue.length === 0 || row.original?.isExpandedSubrow) {
    return true;
  }

  const isEmbeddedField = `${columnId}`.includes("-");
  const parentColumnId = `${columnId}`.split("-")[0];
  let fieldValue = row.original[columnId];

  if (isEmbeddedField) {
    const parentFieldValue = row.original[parentColumnId];
    if ((parentFieldValue?.result || []).length === 0) {
      return false;
    } else {
      fieldValue = extractEmbeddedAttributeValues(columnId, parentFieldValue);
    }
  }

  let formattedFilterValues = filterValue;

  const targetIndex = R.findIndex(R.includes("me-"), filterValue);

  if (targetIndex !== -1) {
    // $FlowFixMe - Flow doesn't yet support optional chaining
    const updatedValue = filterValue[targetIndex]?.split("me-")[1];
    formattedFilterValues = R.update(targetIndex, updatedValue, filterValue);
  }

  if (fieldValue && isEmbeddedField) {
    const parentInstanceResult =
      fieldValue.length === 0
        ? false
        : R.any(value => {
            const result =
              formattedFilterValues.length === 0 ||
              row.original?.isExpandedSubrow
                ? true
                : (value || []).length === 0
                  ? false
                  : R.intersection(formattedFilterValues, value).length > 0;
            return (
              result ||
              filterByBlanksAndNonBlanks(value || [], formattedFilterValues)
            );
          }, fieldValue);
    return parentInstanceResult;
  }

  fieldValue =
    row.original.xGroups?.length > 0 && columnId === "members"
      ? (row.original.xGroups ?? []).reduce(
          (participants, group) => {
            return [...(participants ?? []), ...(group.members ?? [])];
          },
          [...(row.original[columnId] ?? [])]
        )
      : row.original[columnId] ?? [];

  return formattedFilterValues.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.intersection(formattedFilterValues, fieldValue || []).length > 0 ||
        filterByBlanksAndNonBlanks(fieldValue, formattedFilterValues);
};

/**
 * Creates a combined list of group members and users
 * @param {Object[]} fieldValue - value of the user field
 * @return {string[]} - list of uids of all the members
 */
const expandGroups = (fieldValue: Array<UnifizeUser | Group>) => {
  let usersAndGroups = [];
  (fieldValue ?? []).map(participant => {
    if (participant && participant.id && typeof participant.id === "number") {
      // Add group members to the list
      // $FlowFixMe - Already checking if it's a group
      usersAndGroups = [...usersAndGroups, ...(participant.members ?? [])];
      return participant;
    } else if (
      participant &&
      participant.uid &&
      typeof participant.uid === "string"
    ) {
      // Add individual user to the list
      usersAndGroups.push(participant.uid);
      return participant;
    }
  });
  return usersAndGroups;
};

/**
 * Implements filtering by user field
 * @param {Object} row - an instance of a process.
 * @param {number} columnId - column (fieldId) we're filtering by.
 *    Will only have one element which will be the fieldId.
 * @param {string[]} filterValue - column's filter state.
 */
const userFilter = (
  row: Object,
  columnId: number,
  filterValue: any
): boolean => {
  const fieldValue = row.original[columnId];
  const isEmbeddedField = `${columnId}`.includes("-");

  let formattedFilterValues = filterValue;

  const targetIndex = R.findIndex(R.includes("me-"), filterValue || []);

  if (targetIndex !== -1) {
    // $FlowFixMe - Flow doesn't yet support optional chaining
    const updatedValue = filterValue[targetIndex]?.split("me-")[1];
    formattedFilterValues = R.update(targetIndex, updatedValue, filterValue);
  }

  if (fieldValue && isEmbeddedField) {
    // decide whether or not to show the parent instance
    const parentInstanceResult =
      fieldValue.length === 0
        ? false
        : R.any(value => {
            const usersAndGroups = expandGroups(value);

            const result =
              formattedFilterValues.length === 0 ||
              row.original?.isExpandedSubrow
                ? true
                : (usersAndGroups || []).length == 0
                  ? false
                  : R.intersection(formattedFilterValues, usersAndGroups ?? [])
                      .length > 0;
            return (
              result ||
              filterByBlanksAndNonBlanks(
                usersAndGroups ?? [],
                formattedFilterValues
              )
            );
          }, fieldValue);
    return parentInstanceResult;
  }

  const usersAndGroups = expandGroups(fieldValue);

  return formattedFilterValues.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.intersection(formattedFilterValues, usersAndGroups ?? []).length > 0 ||
        filterByBlanksAndNonBlanks(usersAndGroups, formattedFilterValues);
};

/**
 * Implements filtering by form field
 * @param {Object} row - an instance of a process.
 * @param {number} columnId - column (fieldId) we're filtering by.
 * @param {string[]} filterValue - column's filter state.
 */
const formFilter = (
  row: Object,
  columnId: number,
  filterValue: Array<string>
): boolean => {
  const fieldValue = row.original[columnId];
  const isEmbeddedField = `${columnId}`.includes("-");
  if (fieldValue && isEmbeddedField) {
    return R.any(
      value =>
        filterValue.length === 0 || row.original?.isExpandedSubrow
          ? true
          : R.intersection(
              filterValue,
              (value || []).map(form => (form ? form.templateId : null))
            ).length > 0 || filterByBlanksAndNonBlanks(value, filterValue),
      fieldValue
    );
  }
  return filterValue.length === 0 || row.original?.isExpandedSubrow
    ? true
    : R.intersection(
        filterValue,
        (row.original[columnId] || []).map(form => form?.templateId)
      ).length > 0 ||
        filterByBlanksAndNonBlanks(row.original[columnId] || [], filterValue);
};

/**
 * Sorts by priority column (asc & desc)
 * @param {Object} rowA - instance of a process.
 * @param {Object} rowB - instance of a process.
 * @param {string} columnId - column we are sorting by.
 */
const sortPriority = (rowA: Object, rowB: Object, columnId: string) => {
  const priorityA = rowA.original[columnId];
  const priorityB = rowB.original[columnId];

  if (priorityPrecedenceMap[priorityA] === priorityPrecedenceMap[priorityB]) {
    return 0;
  }

  return priorityPrecedenceMap[priorityA] > priorityPrecedenceMap[priorityB]
    ? 1
    : -1;
};

/**
 * Sorts by owner column (asc & desc)
 * @param {Object} rowA - instance of a process.
 * @param {Object} rowB - instance of a process.
 */
const sortOwner = (rowA: Object, rowB: Object) => {
  const ownerA = rowA.original.ownerDisplayName;
  const ownerB = rowB.original.ownerDisplayName;

  if (ownerA === ownerB) {
    return 0;
  }

  return ownerA > ownerB ? 1 : -1;
};

/**
 * Sorts by seqNo (asc & desc)
 * @param {Object} rowA - instance of a process.
 * @param {Object} rowB - instance of a process.
 */
const sortBySeqNo = (rowA: Object, rowB: Object) => {
  return rowA.original.seqNo - rowB.original.seqNo;
};

/**
 * Sorts by picklist field (asc & desc)
 * @param {Object} rowA - instance of a process.
 * @param {Object} rowB - instance of a process.
 * @param {string} columnId - column we are sorting by.
 */
const sortPicklist = (rowA: Object, rowB: Object, columnId: string) => {
  const picklistA = (rowA.original[columnId]?.[0] || "").toLowerCase();
  const picklistB = (rowB.original[columnId]?.[0] || "").toLowerCase();

  if (picklistA === picklistB) {
    return 0;
  }

  return picklistA > picklistB ? 1 : -1;
};

/**
 * Sorts by conversation field (asc & desc)
 * @param {Object} rowA - instance of a process.
 * @param {Object} rowB - instance of a process.
 * @param {string} columnId - column we are sorting by.
 */
const sortConversation = (rowA: Object, rowB: Object, columnId: string) => {
  const conversationA = (
    rowA.original[columnId]?.[0]?.title || ""
  ).toLowerCase();
  const conversationB = (
    rowB.original[columnId]?.[0]?.title || ""
  ).toLowerCase();

  if (conversationA === conversationB) {
    return 0;
  }

  return conversationA > conversationB ? 1 : -1;
};

/** Sorts by date (asc & desc)
 * @param {Object} rowA - instance of a process.
 * @param {Object} rowB - instance of a process.
 * @param {string} columnId - column we are sorting by.
 */
const sortDate = (
  rowA: Object,
  rowB: Object,
  columnId: string,
  desc: boolean
) => {
  const dateA = rowA.original[columnId]
    ? new Date(rowA.original[columnId])
    : null;
  const dateB = rowB.original[columnId]
    ? new Date(rowB.original[columnId])
    : null;

  if (!dateA && !dateB) {
    return 0;
  }
  // Move undefined dates to the end
  if (!dateA) {
    return desc ? -1 : 1;
  }
  // Move undefined dates to the end
  if (!dateB) {
    return desc ? 1 : -1;
  }

  return dateA > dateB ? 1 : -1;
};

/**
 * Returns the right filter & sort functions depening on the
 * field/column
 * @param {string} type - type of the field if the column is a
 *    checklist field.
 * @param {string | number} - column type/fieldId
 */
export const getHeaderColumnSettings = (
  type: ?string,
  key: string | FieldId,
  processColumns: Array<Object>
) => {
  switch (type) {
    case "select":
      return { filterFn: fieldValueFilter, sortingFn: sortPicklist };
    case "text":
    case "number":
    case "file":
      return { filterFn: fieldValueFilter };
    case "user":
      return { filterFn: userFilter };
    case "link":
      return { filterFn: linkedFieldFilter };
    case "conversation":
    case "chatPickList":
    case "workflow":
    case "task":
    case "group":
    case "childConversation":
      return { filterFn: conversationFilter, sortingFn: sortConversation };
    case "date":
      return { filterFn: dateFilter, sortingFn: sortDate };
    case "form":
      return { filterFn: formFilter };
    case "owner":
      return { filterFn: memberFilter };
  }

  switch (key) {
    case "seqNo":
      return { sortingFn: sortBySeqNo };
    case "title":
      return {
        filterFn: (row: Object, columnId: string, filterValue: string) =>
          globalFilter(row, columnId, filterValue, processColumns)
      };
    case "owner":
      return { filterFn: memberFilter, sortingFn: sortOwner };
    case "creator":
    case "members":
    case "completedBy":
      return { filterFn: memberFilter };
    case "priority":
      return { filterFn: priorityFilter, sortingFn: sortPriority };
    case "status":
      return { filterFn: statusFilter };
    case "dueDate":
    case "createdAt":
    case "updatedAt":
    case "completedAt":
      return { filterFn: dateFilter, sortingFn: sortDate };
  }

  return {};
};
