// @flow

import React from "react";
import { useSelector } from "react-redux";
import { getWorkflowName } from "src/reducers";
import ProcessSelect from "src/containers/workflow/select";
import { FormGroup } from "./styles";
import type { WorkflowId } from "src/types";

type Props = {
  id: WorkflowId,
  label: string,
  exclude: Array<WorkflowId>,
  onChange: Function
};

const WorkflowSelect = ({ id, label, exclude, onChange }: Props) => {
  const value: string = useSelector(({ app }) => getWorkflowName(app, id));

  return (
    <FormGroup>
      <label>{label}</label>
      <ProcessSelect
        workflowId={value ? id : null}
        exclude={exclude}
        handleChange={onChange}
      />
    </FormGroup>
  );
};

export default WorkflowSelect;
