// @flow

import React, { useMemo, useRef, useEffect } from "react";
import { List, AutoSizer } from "react-virtualized";

import {
  SelectedContent,
  SelectedItems,
  SelectedItem,
  RemoveButton,
  InputContainer,
  SelectedLabel,
  Input,
  SelectedRow,
  SelectedRowItem,
  RowContent,
  RowClearButton,
  ClearAllWrapper
} from "./styles";
import { Avatar, Checkbox } from "src/components/Unifize";

type Props = {
  showSelectAll?: boolean,
  selected: Array<any>,
  data: Array<Object>,
  keys: Array<string>,
  showIcon: boolean,
  placeholder: string,
  isOpen: boolean,
  selectedRef: any,
  itemsPerRow?: number,
  openMenu: Function,
  handleDeselectAll: Function,
  getSelectedItemProps: Function,
  removeSelectedItem: Function,
  getInputProps: Function,
  getDropdownProps: Function,
  getComboboxProps: Function
};

const VirtualSelection = ({
  showSelectAll,
  selected,
  data = [],
  keys,
  showIcon,
  placeholder,
  isOpen,
  selectedRef,
  itemsPerRow = 3,
  openMenu,
  handleDeselectAll,
  getSelectedItemProps,
  removeSelectedItem,
  getInputProps,
  getDropdownProps,
  getComboboxProps
}: Props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // $FlowFixMe
    inputRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    // $FlowFixMe
    inputRef.current?.focus();
  }, [selected]);

  const renderRow = ({ key, index, style }) => {
    if (index === Math.ceil(selected.length / itemsPerRow)) {
      return (
        <SelectedRow key={key} style={style}>
          <InputContainer>
            <Input
              placeholder={placeholder}
              {...getInputProps({
                ...getDropdownProps({
                  suppressRefError: true,
                  onClick: e => {
                    e.stopPropagation();
                    if (!isOpen) openMenu();
                  }
                }),
                ref: inputRef
              })}
            />
          </InputContainer>
        </SelectedRow>
      );
    }

    const items = [];
    const fromIndex = index * itemsPerRow;
    const toIndex = Math.min(fromIndex + itemsPerRow, selected.length);

    for (let i = fromIndex; i < toIndex; i++) {
      const id = selected[i];
      const selectedItem: Object = data.find(item => item.id === id);
      if (!selectedItem) {
        return null;
      }
      const display: string = keys[1];

      items.push(
        <SelectedRowItem
          key={i}
          {...getSelectedItemProps({
            id,
            index: i
          })}
          onClick={e => {
            e.stopPropagation();
            removeSelectedItem(id);
          }}
        >
          <SelectedLabel title={`${selectedItem[display]}`}>
            {selectedItem[display]}
          </SelectedLabel>
          <RemoveButton>&#10005;</RemoveButton>
        </SelectedRowItem>
      );
    }

    return (
      <SelectedRow key={key} style={style}>
        {items}
      </SelectedRow>
    );
  };

  return (
    <RowContent
      showSelectAll={showSelectAll}
      selected={selected}
      itemsPerRow={itemsPerRow}
      {...getComboboxProps()}
    >
      <AutoSizer>
        {({ height, width }) => {
          const rowCount = Math.ceil(selected.length / itemsPerRow) + 1;
          return (
            <List
              width={width}
              height={rowCount <= 1 ? 32 : rowCount === 2 ? 64 : 96}
              rowCount={rowCount}
              rowHeight={32}
              rowRenderer={renderRow}
              style={{
                paddingRight: "48px",
                backgroundColor: "white",
                position: "relative"
              }}
            />
          );
        }}
      </AutoSizer>
      {showSelectAll && selected.length > 0 && (
        <ClearAllWrapper>
          <RowClearButton onClick={handleDeselectAll}>&#10005;</RowClearButton>
        </ClearAllWrapper>
      )}
    </RowContent>
  );
};

export default VirtualSelection;
