// @flow

import React, { useState } from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string
};

const DeleteTrash = ({ size = 16, color, ...restProps }: Props) => {
  const [isDeleteHover, setIsDeleteHover] = useState(false);

  const handleOnMouseEnter = () => setIsDeleteHover(true);
  const handleOnMouseLeave = () => setIsDeleteHover(false);

  return (
    <svg
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 16 16"
      fill="none"
      {...restProps}
    >
      <g clipPath="url(#clip0_4222_19728)">
        <path
          d="M2.85714 14.2976C2.85714 15.2346 3.62857 16.0013 4.57143 16.0013H11.4286C12.3714 16.0013 13.1429 15.2346 13.1429 14.2976V4.07538H2.85714V14.2976ZM14 1.51982H11L10.1429 0.667969H5.85714L5 1.51982H2V3.22352H14V1.51982Z"
          fill={isDeleteHover ? colors.redDark : color || colors.inkLighter}
        />
      </g>
      <defs>
        <clipPath id="clip0_4222_19728">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteTrash;
