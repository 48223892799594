// @flow

import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { DownshiftProps } from "downshift";
import ReactProgressiveList from "react-progressive-list";
import { VStack, Text, Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import Dropdown from "src/components/Dropdown";
import NewUser from "src/components/user/SelectMultipleInvite/NewUser";
import NewGroup from "src/components/user/SelectMultipleInvite/NewGroup";
import RemoveSingleUser from "./RemoveSingleUser";
import AddData from "../AddData";
import useFields from "../useFields";
import { validateEmail, getEmailPrefix } from "src/utils";
import { getUserAndGroupIds, getMultipleSelectedValues } from "./utils";

import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import {
  getChecklistFieldDetails,
  getChecklistValue,
  getFilteredSearchResults,
  getAllMembersAndGroups,
  getWhetherMandatoryField,
  getLockedStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  getFormFieldMandatoryStatus,
  getSelectedChecklist,
  isProcessRowSelected
} from "src/reducers";

import location from "src/constants/location";
import * as styles from "./styles";
import type {
  FieldId,
  RoomId,
  UsersAndGroups,
  ColumnId,
  HttpMethods,
  FieldValue
} from "src/types";

type Props = {
  fieldId: FieldId,
  roomId: RoomId,
  formId: ?number,
  roomFieldFormId: ?string,
  location: ?string,
  edit: boolean,
  openEdit: () => void,
  closeEdit: () => void,
  fromManageView?: boolean,
  selectedFieldValue: any,
  setSelectedFieldValue: ?(any) => void,
  handleClose: Function
};

const UserDropdown = ({
  fieldId,
  roomId,
  formId,
  roomFieldFormId,
  location: sourceLocation,
  edit,
  openEdit,
  closeEdit,
  fromManageView = false,
  selectedFieldValue,
  setSelectedFieldValue,
  handleClose
}: Props) => {
  const dispatch = useDispatch();

  const {
    index,
    embeddedIndex,
    columnId,
    roomId: selectedRoomId
  } = useSelector(({ app }) => getSelectedChecklist(app));
  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );
  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const orgUsersAndGroups = useSelector(({ app }) =>
    getAllMembersAndGroups(app)
  );

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, `${fieldId}`)
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const { settings, value: extractedValue } = useFields({
    checklistValue,
    details
  });

  const value = (fromManageView ? selectedFieldValue : extractedValue) ?? [];

  const multiple: boolean = settings && settings.multiple;

  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailInvite, setEmailInvite] = useState(false);
  const [debouncedSearch]: [string] = useDebounce<string>(query, 100);
  const [placeholder, setPlaceholder] = useState("Select users");

  const searchedFilteredResults: [UsersAndGroups] = useSelector(({ app }) =>
    getFilteredSearchResults(
      app,
      debouncedSearch,
      multiple,
      userAndGroupIds || []
    )
  );
  const userAndGroupIds = getUserAndGroupIds(value);
  const multipleSelectedValues = getMultipleSelectedValues(
    orgUsersAndGroups,
    userAndGroupIds
  );

  const filteredResults = searchedFilteredResults.filter(({ id }) => {
    return !(value || []).includes(id);
  });
  const downshiftRef = useRef<DownshiftProps>(null);

  const onQueryChange = useCallback(
    input => {
      setQuery(input);
      if (filteredResults.length === 0)
        setErrorMessage(`No results for ${input}`);
    },
    [setQuery, filteredResults]
  );

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        } else {
          setSelectedFieldValue && setSelectedFieldValue(value);
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          httpMethod,
          value,
          progress: true,
          formId,
          columnId
        });
      }
    },
    []
  );

  const handleSelect = useCallback(
    ({ id }) => {
      if (multiple) {
        if (typeof id === "string") {
          dispatch(
            setChecklistFieldValue({
              roomId,
              id: fieldId,
              value: {
                value: [
                  ...(multipleSelectedValues || []),
                  {
                    uid: id,
                    type: "user"
                  }
                ],
                type: "user",
                checked: true
              },
              progress: true,
              formId,
              columnId
            })
          );
        } else {
          dispatch(
            setChecklistFieldValue({
              roomId,
              id: fieldId,
              value: {
                value: [
                  ...(multipleSelectedValues || []),
                  {
                    id,
                    type: "group"
                  }
                ],
                type: "user",
                checked: true
              },
              progress: true,
              formId,
              columnId
            })
          );
        }
      } else {
        dispatch(
          setChecklistFieldValue({
            roomId,
            id: fieldId,
            value: {
              value: {
                uid: id,
                type: "user"
              },
              type: "user",
              checked: true
            },
            progress: true,
            formId,
            columnId,
            httpMethod: "POST"
          })
        );
      }
      closeEdit();
      setQuery("");
    },
    [multiple, value, setQuery, setChecklistFieldValue, searchedFilteredResults]
  );

  const handleKeyboardSelect = useCallback(
    selectedItem => {
      if (selectedItem) {
        handleSelect({ id: selectedItem });
      }
    },
    [handleSelect]
  );

  const removeUser = useCallback(
    value => {
      let userId;
      if (typeof value === "object") {
        userId = value?.uid;
      } else {
        userId = value;
      }
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: {
              uid: userId,
              type: "user"
            },
            type: "user",
            checked: true
          },
          progress: true,
          formId,
          columnId,
          httpMethod: "DELETE"
        })
      );
    },
    [value, setChecklistFieldValue]
  );

  const handleInvite = useCallback(
    email => {
      if (multiple) {
        dispatch(
          setChecklistFieldValue({
            roomId,
            id: fieldId,
            value: {
              value: {
                type: "user",
                email,
                displayName: getEmailPrefix(email)
              },
              type: "user",
              checked: false
            },
            httpMethod: "PATCH",
            progress: true,
            formId,
            columnId
          })
        );
      } else {
        dispatch(
          setChecklistFieldValue({
            roomId,
            id: fieldId,
            value: {
              value: {
                type: "user",
                email,
                displayName: getEmailPrefix(email)
              },
              type: "user",
              checked: false
            },
            progress: true,
            formId,
            columnId
          })
        );
      }

      closeEdit();
      setQuery("");
    },
    [closeEdit, setQuery]
  );

  if (edit) {
    return (
      <Dropdown
        onItemClick={handleSelect}
        isOpen={edit}
        ref={downshiftRef}
        onOuterClick={closeEdit}
        inputFieldSize="sm"
        query={query}
        onQueryChange={onQueryChange}
        placeholder={placeholder}
        handleKeyboardSelect={handleKeyboardSelect}
        closeDropdown={closeEdit}
        customStyles={{
          DropdownMenuWrapper: styles.DropdownMenuWrapper,
          DropdownWrapper:
            sourceLocation === location.checklistDock && styles.DropdownWrapper
        }}
      >
        {({ onItemClick, getItemProps, highlightedIndex, scrollIntoView }) => {
          return (
            <>
              {!multiple && value.length > 0 && (
                <RemoveSingleUser handleRemove={() => removeUser(value[0])} />
              )}
              {filteredResults.length > 0 && !isEmailInvite ? (
                <>
                  <VStack spacing="0" sx={styles.DropdownList}>
                    <ReactProgressiveList
                      initialAmount={10}
                      progressiveAmount={10}
                      role="list"
                      rowCount={filteredResults.length}
                      renderItem={index => {
                        if (filteredResults[index]) {
                          const { id, type } = filteredResults[index];
                          if (type === "user") {
                            return (
                              <NewUser
                                item={filteredResults[index]}
                                key={`user-${id}`}
                                index={index}
                                highlightedIndex={highlightedIndex}
                                scrollIntoView={scrollIntoView}
                                {...getItemProps({
                                  item: id,
                                  id: id,
                                  onItemClick: onItemClick
                                })}
                              />
                            );
                          } else if (type === "group") {
                            return (
                              <NewGroup
                                item={filteredResults[index]}
                                key={`group-${id}`}
                                index={index}
                                highlightedIndex={highlightedIndex}
                                scrollIntoView={scrollIntoView}
                                {...getItemProps({
                                  item: id,
                                  id: id,
                                  onItemClick: onItemClick
                                })}
                              />
                            );
                          }
                        }
                      }}
                    />
                  </VStack>
                  <Button
                    sx={styles.InviteExternal}
                    leftIcon={<AddIcon boxSize={2} focusable />}
                    variant="link"
                    onClick={() => {
                      setEmailInvite(true);
                      setPlaceholder("Enter user's email");
                    }}
                  >
                    Add new user
                  </Button>
                </>
              ) : isEmailInvite || validateEmail(query) ? (
                <Button
                  sx={styles.InviteExternal}
                  leftIcon={<AddIcon boxSize={2} focusable />}
                  variant="link"
                  onClick={() => {
                    setEmailInvite(false);
                    setPlaceholder("Select users");
                    if (validateEmail(query)) {
                      handleInvite(query);
                    } else {
                      setErrorMessage("Enter valid email");
                    }
                  }}
                >
                  Add new user
                </Button>
              ) : (
                <Text sx={styles.ErrorMessage}>{errorMessage}</Text>
              )}
            </>
          );
        }}
      </Dropdown>
    );
  }

  if (!multiple && value.length === 0) {
    return (
      <AddData
        disabled={locked}
        type="user"
        handleClick={openEdit}
        isMandatory={isMandatory}
      />
    );
  }

  if (multiple) {
    return (
      <AddData
        disabled={locked}
        type="user"
        handleClick={openEdit}
        isMandatory={isMandatory}
      />
    );
  }

  return null;
};

export default UserDropdown;
