// @flow

import React from "react";
import { connect, useSelector } from "react-redux";

import useBoolean from "src/hooks/useBoolean";
import type { State, WorkflowInstances, ColumnId } from "src/types";
import {
  toggleProcessVersionsVisibility,
  updateWorkflowInstanceCurrentVersion
} from "src/actions/workflows";

import Icon from "src/icons";
import {
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button
} from "@chakra-ui/react";
import { getFieldWidth } from "src/reducers";
import { EditIconWrapper } from "../styles";
import { Count, CaretUp, MultiContainer, StyledMultiField } from "./styles";

type Props = {
  expanded: boolean,
  process: WorkflowInstances,
  index: number,
  hideEdit: ?boolean,
  allRevisionsShown: boolean,
  toggleProcessVersionsVisibility: Function,
  updateWorkflowInstanceCurrentVersion: Function,
  columnId: ColumnId,
  selected: boolean
};

const Revision = ({
  expanded,
  process,
  index,
  hideEdit,
  toggleProcessVersionsVisibility,
  updateWorkflowInstanceCurrentVersion,
  allRevisionsShown,
  columnId,
  selected
}: Props) => {
  const {
    value: isPopoverOpen,
    toggleBoolean: togglePopover,
    setFalse: hidePopover
  } = useBoolean();
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  const { version, currentVersion, versionCount, chatroomId, seqNo } = process;

  const toggleVersionsVisibility = () => {
    toggleProcessVersionsVisibility(seqNo);
  };

  const updateCurrentVersion = () => {
    updateWorkflowInstanceCurrentVersion({ chatroomId, seqNo, index });
    hidePopover();
  };

  return (
    <MultiContainer
      selected={selected}
      width={fieldWidth}
      embedded={columnId && columnId.includes("-")}
      borderRequired={columnId.includes("-")}
    >
      <StyledMultiField maxWidth={fieldWidth}>
        <HStack width="100%" justifyContent="space-between" mr={1}>
          <HStack>
            <span>{version}</span>
            {currentVersion && expanded && <span>C</span>}
          </HStack>
          {!allRevisionsShown ? (
            <>
              {currentVersion &&
                versionCount > 1 &&
                (expanded ? (
                  <CaretUp onClick={toggleVersionsVisibility}>
                    <Icon type="caretUp" />
                  </CaretUp>
                ) : (
                  <Count onClick={toggleVersionsVisibility}>
                    +{versionCount - 1}
                  </Count>
                ))}
            </>
          ) : null}
        </HStack>

        {!hideEdit && (
          <Popover
            placement="bottom-start"
            isOpen={isPopoverOpen}
            closeOnBlur={true}
            onClose={hidePopover}
          >
            <PopoverTrigger>
              <EditIconWrapper onClick={togglePopover}>
                <Icon type="editPencil" />
              </EditIconWrapper>
            </PopoverTrigger>

            <PopoverContent width="105px">
              <PopoverBody p={0}>
                <Button
                  variant="uBlueOutlined"
                  size="xs"
                  onClick={updateCurrentVersion}
                >
                  Make as current
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </StyledMultiField>
    </MultiContainer>
  );
};

const mapStateToProps = ({ app }: State, { process }: Props) => ({
  expanded: app.workflow.showVersions.get(`${process.seqNo}`)
});

const mapDispatchToProps = {
  toggleProcessVersionsVisibility,
  updateWorkflowInstanceCurrentVersion
};

export default connect(mapStateToProps, mapDispatchToProps)(Revision);
